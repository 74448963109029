import { ILoreTextWall } from "./LoreTextWalls";

export const WALL: ILoreTextWall = {
  title: "Razze",
  shortDescription: "Le razze di questo mondo",
  imagePath: "/assets/races.webp",
  sections: [
    {
      title: "La mappa del mondo",
      shortDescription: "La mappa del mondo di Gal Abdur",
      content: [
        {
          type: "title",
          value: "Mappa del mondo di Gal Abdur",
        },
        {
          type: "text",
          value:
            "Questa mappa del mondo fu disegnata dal collettore Gal Abdur, nel 2009 della terza era, uno dei più grandi esploratori della storia. " +
            "La sua peculiarità è che questa fu la prima mappa in cui il continente di Vaniaa è stato rappresentato fedelmente. " +
            "Per via dell'inaccessibilità di quelle isole ritenute terre maledette, nessuno oltre Gal Abdur era mai riuscito a " +
            "descriverne tutti i confini. I nomi delle isole di Vaniaa furono scelti da questo esploratore e in riconoscenza dei " +
            "suoi meriti tutte le culture mondiali hanno adottato le sue notazioni.  ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/mappagalabdur.webp"} />
            </div>
          ),
        },
      ],
    },
    {
      title: "Popoli di Fedora",
      shortDescription:
        "I popoli dei cinque grandi paesi del continente di Fedora.",
      content: [
        {
          type: "title",
          value: "Korianda, il centro-ovest di Fedora, terza Era",
        },
        {
          type: "text",
          value:
            "Nel centro di Fedora, sulla costa ovest, troviamo lo stato di Korianda, forse il paese con la storia più travagliata del continente. " +
            "Principalmente pianeggiante, Korianda vive di prodotti della terra e di pesca, nonché delle ricchezze minerarie del sottosuolo. " +
            "Lo stato è relativamente di recente nascita. " +
            "Infatti, tra gli anni 500 e 2100 della terza le terre di Korianda erano divise tra molti stati minori tutti in conflitto tra loro. " +
            "Quei rari periodi di pace che si sperimentavano erano dovuti ai tempi di ripristino interno delle risorse di tutte le entità coinvolte. " +
            "Le cose cambiarono attorno al 2658. Uno degli stati del centro del continente, noto come l'impero di Arkhan, avviò una spietata campagna bellica " +
            "alimentata da una propaganda nazional socialista che lo portò in pochi anni ad occupare tutti i territori dell'attuale stato di Korianda, andando anche oltre." +
            "L'espansione e il controllo dei territori fu possibile grazie alla scoperta di un prodigioso arsenale di antichi reperti ancora funzionanti che potevano essere " +
            "usati per infliggere danni pesanti ai nemici senza che questi potessero reagire. L'impossibilità di sconfiggere con la forza l'impero di Arkhan spinse gli stati " +
            "assoggettati a cercare un dialogo interno al fine di resistere all'azione dell'impero. Dopo due secoli, il dialogo tra gli stati annessi era divenuto così strutturato " +
            "ed efficace che l'impero di Arkhan iniziò a temerlo. Per controllare questi canali di comunicazione, l'impero stesso fondò un Gabinetto con rappresentanti di tutti gli stati annessi, " +
            "ciò aveva lo scopo di centralizzare il luogo del dibattito in una sola istituzione, al fine di riuscire a controllarla più facilmente. ",
        },
        {
          type: "text",
          value:
            "Nell'anno 2741 poi scoppiò una nuova guerra con i paesi del sud che si erano riuniti nella federazione dell'Olongea e avevano mosso un'invasione fulminea nei confini sud di Korianda. " +
            "Questo portò i paesi già annessi dall'impero di Arkhan a stringere ancor più la loro alleanza interna, in quanto avevano vissuto l'aggressione dell'Olongea come un'assalto efferato. " +
            "Al tempo ancora non era chiaro che in realtà l'Olongea aveva solo risposto a molte provocazioni causate dall'impero di Arkhan che si erano protratte nel tempo. I paesi nel sud dell'impero " +
            "erano retrogradi e rurali, e furono facilmente manipolati dalla retorica del loro imperatore. Ai loro occhi, la difesa da parte dell'impero era la loro unica speranza di sopravvivenza. " +
            "Il nemico del sud fu respinto dopo vent'anni di conflitto, e la guerra ridefinì i confini di quelle terre. La guerra appena vinta dall'impero di Arkhan non poté trasformarsi in un'invasione " +
            "su vasta scala per via di forti rivolte interne al paese. Questo diede all'Olongea tempo di riformarsi." +
            "Nell'anno 3189 poi ci fu un nuovo conflitto tra l'impero di Arkhan con Olongea e Migulma, entrambi alleati contro di lui. " +
            "Inizialmente l'impero di Arkhan parve riuscire a tener a bada i due nemici, il conflitto era lento e faticava ad avere consensi su entrambi i fronti. Andò avanti così fino al 3210, quando l'impero di Arkhan riuscì a uccidere la famiglia " +
            "reale di Migulma. Da lì la guerra esplose e divenne conflitto totale. Spinta da interessi politici, anche l'Olongea si inserì a pieno carico contro l'impero." +
            "Al che l'impero di Arkhan si alleò con gli stati all'estremo sud del continente, ossia la nazione del Dulenvir, storica nemica dell'Olongea. Furono circa cinquant'anni di distruzione e odio che si riversavano nelle trincee." +
            "La guerra fu molto dura e ci furono gravi perdite su tutti i fronti. Durò fino a quando un gruppo di assassini scelti inviati da Migulma, aiutati da una rete di spie di Phivalla, riuscirono a raggiungere l'imperatore di Arkhan e ucciderlo nel 3258 assieme a molti " +
            "alti ufficiali dell'esercito. Così, la guerra dovette concludersi e l'impero di Arkhan discusse le condizioni della fine del conflitto con Migulma e Olongea.",
        },
        {
          type: "text",
          value:
            "Rimasto da solo a combattere, anche il Dulenvir depose le armi. Era l'unico stato ancora in forze alla fine del conflitto, ma non avrebbe cercato di invadere l'Olongea da solo, il loro governo temeva infatti che Migulma si potesse immischiare." +
            "Migulma e Olongea non tentarono di occupare i paesi sconfitti. Non avevano altre forze da investire in una vera occupazione totale. La loro espansione portò i rispettivi confini fino alle catene montuose al centro del continente, il cui accesso era stato fino a quel momento ad uso esclusivo dell'impero di Arkhan. " +
            "Vista la loro vittoria formale, i due stati di Migulma e Olongea erano nella posizione di dettare alcune condizioni sulla resa del nemico. Imposero all'impero di Arkhan di cambiare la struttura del suo governo perché ricadesse in categorie più " +
            "compatibili con le convenzioni internazionali che furono discusse in quei giorni. In questo modo nacque la repubblica democratica presidenziale di Korianda. " +
            "Phivalla, avendo partecipato solo in parte minore, aveva una sola pretesa. Chiese di essere riconosciuta come stato sovrano e questo avvenne nel 3259." +
            "Da allora Korianda è il cuore commerciale di Fedora, e le vie più sicure tra nord e sud passano per le sue pianure. La vecchia alleanza bellica con il Dulenvir si trasformò in una complicità commerciale molto forte che dura sino ad oggi.",
        },
        {
          type: "title",
          value: "Migulma, il deserto dell'est, terza Era",
        },
        {
          type: "text",
          value:
            "La parte orientale di Fedora era inizialmente divisa in tanti staterelli, simili a clan feudali, i quali erano governati dalle famiglie nobili locali. " +
            "Solo due cose erano condivise da tutti i clan: la religione e l'obbedienza alla gerarchia dei clan nobili, ossia i clan che avevano egemonia su tutti gli altri, " +
            "egemonia guadagnata nel corso della storia a seguito di forti conflitti e alleanze politiche. La struttura di quelle terre è rimasta invariata per molti secoli, " +
            "fino alle prime guerre di conquista mosse dall'impero di Arkhan. Attorno al 2782 la pressione sui confini dei clan più esposti rese chiaro che serviva una risposta " +
            "pragmatica all'incombenza del conflitto, così i clan nobili si riunirono e fondarono la lega di Ukasai, che istituzionalizzava un primo tentativo di governo centrale. " +
            "Dopo due secoli, nel 2974, la lega poté evolvere ancora, raffinando la sua struttura in un'oligarchia con un regnante come figura centrale, il quale però non aveva alcun " +
            "reale potere. Il controllo del sistema politico era infatti basato su tre principi, ossia il potere religioso della casta sacerdotale, la repubblica rappresentativa dei " +
            "clan e il consiglio degli oligarchi appunto, che era l'organo legislativo del regno. Grazie a tutte queste opere di riforma interna, le regioni della costa poterono resistere " +
            "alle invasioni dell'esercito dell'impero di Arkhan. Migulma riuscì a fermare il grande esercito nemico anche grazie ai rinforzi che giunsero dall'oltremare, precisamente dal continente esotico del Gunebar, loro alleato commerciale.",
        },
        {
          type: "title",
          value: "Phivalla, la terra del nord, terza Era",
        },
        {
          type: "text",
          value:
            "A nord del continente di Fedora troviamo lo stato innevato di Phivalla. Oggi è una democrazia presidenziale con la fama di essere il cuore delle scienze e della tecnologia " +
            "del mondo moderno. Estremamente ricca di risorse minerarie ma militarmente poco avanzata, Phivalla gioca un ruolo marginale nelle questioni internazionali per via di una sua politica " +
            "di non coinvolgimento che spesso ha causato ai popoli del nord il titolo di menefreghisti. Inizialmente per lo più disabitata, era casa di vari popoli nomadi di piccole dimenzioni, " +
            "fu poi colonizzata in modo più significativo a partire dal nono secolo della terza era. " +
            "La colonizzazione di Phivalla avvenne ad opera dei profughi di ogni nazione che fuggirono da varie situazioni di povertà da tutto il continente, e si unirono ad alcune colonie indipendenti che si erano spostate a nord nei secoli precedenti. " +
            "Lo stato di Phivalla proclamò la sua nascità ufficiale nel 1208 con la stesura della sua costituzione, annunciandosi come stato federale presidenziale con un suo governo indipendente. " +
            "La sua nascita avvenne proprio nel momento storico giusto. In altri contesti probabilmente lo stato di Korianda, al tempo l'impero di Arkhan, l'avrebbe certamente rasa al suolo e annessa solo per dimostrare " +
            "il suo potere imperialista. Ci furono in effetti alcuni tentativi di assoggettare Phivalla durante i suoi periodi di gestazione, ma lo stato sopravvisse perché l'impegno dell'impero di Arkhan fu minimo. " +
            "Le terre del nord erano al tempo poco conosciute ed erano viste come poco ricche e l'esercito dell'impero di Arkhan era già occupato con la guerra mondiale in cui era coinvolto. " +
            "Era infatti in corso la prima guerra mondiale del 3200 che coinvolgeva l'impero di Arkhan (Attuale Korianda) alleata con il Dulenvir contro Migulma e Olongea.",
        },
        {
          type: "text",
          value:
            "Così Phivalla trovo poca resistenza, riuscì a fortificarsi e si unì alla grande guerra solo nei suoi ultimi anni, così da poter sedere al tavolo dei vincitori e domandare di essere riconosciuta." +
            "La popolazione di Phivalla è un agglomerato multiculturale che ruba usanze e costumi dai più maturi popoli del centro sud. Alterna città affollate e campagne scarsamente popolate. Sebbene sia uno stato indipendente, " +
            "la sua necessità di beni alimentari è tale da richiedere un continuo afflusso proveniente dagli altri stati, con i quali ha stabilito diversi accordi commerciali. Nelle questioni internazionali, Phivalla tiene un profilo basso, quasi nullo, " +
            "con l'eccezione del mercato globale di metalli rari, di cui è ricca. In quel frangente, Phivalla coordina la maggior parte delle attività diplomatiche relative al commercio di quelle risorse. Il potere di questo stato deriva principalmente " +
            "dalle scorte naturali di materiali rari, gas e petrolio, di cui dispone in quantità spropositate.",
        },
        {
          type: "title",
          value: "Olongea, la congrega degli stati del sud, terza Era",
        },
        {
          type: "text",
          value:
            "L'Olongea era inizialmente una vasta regione di piccoli stati indipendenti, che si estendevano dalla costa sud di Fedora fino alle catene montuose centrali. " +
            "Durante le prime guerre d'espansione per il dominio di Arkhan, nel 2721, i numerosi popoli nell'ansa sud di Fedora decisero di unire le " +
            "forze difronte al potenziale nemico. Tale alleanza prese il nome di Olongea, la congrega degli statu del sud. A differenza di Migulma, L'Olongea " +
            "si trovava fin da subito in una condizione di gran disordine politico durante il periodo delle guerre, e se l'impero arkhanita non fosse stato rallentato dalle " +
            "sue insurrezioni interne, probabilmente sarebbe stata spazzata via. A differenza di Migulma, non venne mai dichiarato lo stato unico. I vari stati che la " +
            "compongono rimangono indipendenti l'uno dall'altro, sia politicamente che culturalmente. Ogni stato minore mantiene le sue forme di governo, con una condizione. " +
            "Ogni stato minore doveva eleggere, secondo le sue norme, un rappresentante estero. Tale rappresentante si riuniva ogni tre mesi con quelli degli altri stati, " +
            "formando il gran consiglio dei ministri. Tale consiglio aveva scopo consultativo ma, nei casi di pericolo o necessità, poteva raccogliere in sé il potere esecutivo " +
            "e deliberare impartendo ordini ad uno o anche tutti gli stati dell'Olongea. Tale congrega in quei casi applicava il potere decisionale massimo, nessun governo minore " +
            "poteva ignorare una diretta richiesta dei ministri esteri. " +
            "Questa insolita forma di governo era la migliore soluzione politica trovata dai reggenti degli stati del sud per riunire in estrema difesa i poteri di tutta l'Olongea. " +
            "Era ben noto a tutti quegli stati che la loro sopravvivenza sarebbe dipesa direttamente dalla loro capacità di collaborare con i vicini." +
            "Gli stati membri dell'Olongea godevano anche di alcuni benefici, come una legge fiscale intelligente per la gestione dell'economia a supporto di un mercato libero." +
            "Dal punto di vista militare, l'Olongea disponeva di enormi eserciti, ma spesso erano impiegati per scontri interni tra gli stati della federazione. Scontri che erano " +
            "sopportati dal consiglio dei ministri, i quali spesso dovevano arrivare a intervenire direttamente per mettere ordine e freno alle rivalità.",
        },
        {
          type: "title",
          value: "Dulenvir, le isole del sud di Fedora, terza Era",
        },
        {
          type: "text",
          value:
            "Il Dulenvir, popolo di bellicosi confinato nelle isole del sud fino dai primi secoli della terza era. " +
            "Unificato tramite guerre locali e accordi commerciali, il Dulenvir era governato da una dittatura militare largamente accettata su tutto il loro territorio. " +
            "Prima che Fedora si stabilizzasse come è oggi, l'Olongea e Migulma dovettero combattere sanguinose guerre contro questa dittatura dinastica. " +
            "Il Dulenvir premeva sui confini dell'sud dell'Olongea, colpendo gli stati liberi di Asantor e Vursh con violenza e repentinità. Fu proprio la loro furia " +
            "che fece scatenare al nord la fame di potere dell'impero arkhanita. Alleandosi tra loro, il Dulenvir e l'impero Arkhan mossero guerra al resto di Fedora durante " +
            "gli anni che andarono dal 3210 al 3258. Una guerra che stava per portare alla rovina l'Olongea. Sebbene Migulma fosse in stallo con Korianda, al sud il Dulenvir " +
            "stava schiacciando i suoi nemici, tanto che riuscì a spingere il suo dominio ben oltre le sue isole, andando in profondità nell'entroterra. Se l'Olongea fosse stata " +
            "distrutta, Migulma si sarebbe trovata attaccata da due fronti, senza possibilità di salvezza. Per loro fortuna, il popolo di Korianda si ribellò alle decisioni imperialistiche " +
            "dopo una lunga guerra civile che rovesciò il potere a seguito della morte dell'imperatore, che fu causata da un'operazione di truppe speciali di Migulma e Phivalla. ",
        },
        {
          type: "text",
          value:
            "I capi della rivoluzione, assieme al figlio dell'imperatore ormai sfinito, si riunirono con i capi degli stati, Olongea e Migulma, sotto la direzione dei quali firmarono " +
            "una costituzione sulla quale costruirono il nuovo governo di stampo democratico, la cui prima decisione fu di uscire dalla guerra e liberare tutti i prigionieri Migulmeni. " +
            "Korianda quindi passò virtualmente al fianco dell'Olongea e di Migulma, senza mai dichiarare aperta l'ostilità contro il nemico del sud. " +
            "Il Dulenvir, vista la situazione, dichiarò l'arresto incondizionato del conflitto, mantenendo però il controllo delle terre conquistate sino a quel momento. Per oltre duemila " +
            "anni Fedora non vide più guerre maggiori, i vari popoli maturarono una più civile capacità di relazionarsi, nonostante continuassero conflitti sanguinosi in regioni più isolate dei vari stati. " +
            "In tutto questo spargere di sangue, Phivalla al nord cresceva in ricchezza e sapienza. Secondo alcuni documenti non del tutto confermati, la forza ribelle di Korianda vinse " +
            "la guerra civile grazie al silente intervento di truppe speciali di Phivalla che avevano disteso una rete di rifornimenti al servizio delle forze ribelli di Korianda. " +
            "L'attività di direzionare la storia lavorando sottobanco è da allora un tratto caratterizzante dello stato del nord. Nonostante quel gesto, negli anni successivi divenne evidente che l'alleanza " +
            "più salda tra gli stati di Fedora si istituì tra Korianda e Phivalla. Al sud, il Dulenvir perse molto del suo desiderio di lotta, trasformandosi anche lui in una repubblica presidenziale, imitando i paesi del nord. " +
            "Con il tempo divenne evidente che il potere in quello stato era comunque accentrato in un oligarchia delle famiglie dei generali, ma questo, almeno di facciata, veniva negato.",
        },
      ],
    },

    {
      title: "Popoli del Gunebar",
      shortDescription: "I popoli delle grandi isole del Gunebar.",
      content: [
        {
          type: "title",
          value: "Gunebar, il continente esotico, terza Era",
        },
        {
          type: "text",
          value:
            "La storia del Gunebar è stata completamente isolata da quella di Fedora per moltissimi secoli. Alla comparsa delle grandi epidemie che devastarono i popoli di Fedora " +
            "durante la fine della seconda era, tutti i popoli del Gunebar concordarono di chiudere i porti e sigillarsi nelle loro isole. Questo permise loro di sopravvivere di " +
            "fatto alla devastazione di quegli anni, ma anche ridusse al nulla i contatti con Fedora. " +
            "La distruzione di quegli anni fu tanto violenta, che anche nei secoli successivi, nessuno del Gunebar volle rischiare e andare sul continente funestato da piaghe " +
            "e tormenti. Tanto più che i pochi esploratori più folli, partirono e non tornarono mai. Questo silenzio durò quasi sette secoli. " +
            "Anche questo continente ha conosciuto guerre e conflitti, e questo fa sì che internamente gli stati di questo paese nutrano molti reciproci antichi rancori. " +
            "Oggi i confini politici sono quasi definiti unicamente dal mare. Ogni isola è indipendente e compatta politicamente all'interno, spesso divisa in ampie regioni con " +
            "governi locali che rispondono al potere centrale solo per questioni di un certo rilievo. Questo continente ha visto molti più conflitti di Fedora. Tuttavia, si trattava " +
            "di battaglie molto brevi. La forte influenza dei sacerdoti dei sette e del loro credo agì sempre come modulatore dell'astio, permettendo una gestazione meno sanguinosa " +
            "dei governi locali. Il culto dei sette, dapprima presente solo in forma marginale in quel continente, prese piede durante i primi anni della terza era grazie all'azione " +
            "incessante dell'attività missionaria del sacerdote Opsei, ritenuto massimo esponente di quel culto. Il sacerdote riuscì a unificare tutti i culti del Gunebar e nel 298 li " +
            "ricondusse tutti al culto dei sette, fondando ufficialmente la loro istituzione. Tutte le isole del Gunebar da allora seguono politiche fortemente influenzate dai dogmi della " +
            "religione politeista dei sette, notoriamente pacifista è propensa alla valorizzazione di vita, arte, scienza e cultura. Della storia antica del Gunebar basta dire che, prima " +
            "dell'avvento della religione dei sette, il continente era un caotico agglomerato di casate rivali. La tensione tra tali casate perdura ancora oggi, senza sfociare però negli " +
            "allora molto comuni omicidi dei primogeniti. I loro contatti con Fedora avvennero attorno all'anno 2671, ma solo in modo sporadico e marginale. " +
            "Furono gli Straahl di Fedora, uno dei clan della futura Migulma, ad approdare per primi sulle coste del Gunebar. " +
            "Questo contatto fu fortuito. La piccola flotta Straahl era finita fuori rotta per via di una tempesta. I pochi superstiti giunsero per miracolo sulle coste del Gunebar, " +
            "dando prova ai popoli di quel continente che le piaghe di Fedora erano di fatto superate. " +
            "Gli Straahl rimasero affascinati dalle ricchezze culturali e materiali di quelle terre.  " +
            "Il Gunebar era, ed è tutt'oggi, ricco e fiorente. Purtroppo, ai tempi dei primi incontri gli Straahl si erano avventurati senza la minima nozione della lingua locale " +
            "e delle usanze e questo rese arduo pontificare tra le due culture. I superstiti della tempesta furono aiutati e ricondotti in patria. Dopo quell'esperienza, il popolo Straahl " +
            "aumentò la sua allerta nei confronti del Gunebar e il loro capo inviò flotte più numerose verso le coste del continente da poco riscoperto. " +
            "La prima spedizione fu nel 2680, e poi altre furono inviate nel 2682 e 2686. ",
        },
        {
          type: "text",
          value:
            "Purtroppo non tutto andò liscio nell'ultima spedizione. Ci furono incomprensioni da cui sfociarono brevi battaglie molto intense, dove i guerrieri del Gunebar, chiamati Irlaki, " +
            "spazzarono via le flotte Straahl. Gli Irlaki erano guerrieri devoti alle casate regnanti del Gunebar, più che ai sacerdoti dei sette, e passavano tutta la vita a sottoporsi ad " +
            "addestramenti estenuanti. " +
            "Il loro antico ordine aveva radici addirittura nella seconda era, così come il culto dei sette. Tale ordine era estremamente selettivo riguardo l'accettazione di nuovi membri, " +
            "successivamente si sarebbe estinto attorno all'anno 1957 della terza era. Ogni Irlaki era un maturo conoscitore dell'arte della lotta per la difesa, della strategia bellica e " +
            "dell'uso del Dharma Talgalla. Dopo questi scontri nacque un astio tra le civiltà dei due continenti, ed i contatti successivi furono minimali. " +
            "Nonostante ciò, nel Gunebar si soffriva per l'impossibilità dello scambio culturale con il mondo oltremare. Così, in segreto, piccole spedizioni di studiosi e accademici " +
            "provenienti da entrambi i fronti si incontrarono pacificamente sull'isola di Volun, allora sede del popolo di Shibun, anche lui parte della lega Ukasai. Tali incontri vennero presto scoperti dalla dinastia lì " +
            "regnante, ma non furono mai ostacolati. Andarono avanti per secoli, permettendo ad entrambe le fazioni di apprendere la lingua e le basi culturali delle controparti.  " +
            "La pace purtroppo non fu mai considerata accettabile per l'orgogliosa dinastia Straahl, e questo creò notevoli tensioni politiche interne alla lega Ukasai. " +
            "Secoli dopo, durante le invasioni Arkhanite, dopo la fusione degli stati della lega nello stato di Migulma, gli incontri con il Gunebar dovettero interrompersi del tutto. " +
            "Da parte del i popoli dell'isola di Libaldat pensarono che quella fosse l'occasione di farsi perdonare le guerre dei primi tempi. " +
            "Usarono la guerra di Fedora come mezzo risanante. Ambasciatori Libaldeni chiesero con umiltà la possibilità di sdebitarsi della loro passata aggressione agli Straahl, " +
            "unendosi a Migulma nella guerra. Vista la situazione Migulma, e gli Straahl in particolare, dovettero accettare.  ",
        },
        {
          type: "text",
          value:
            "Le risorse e le armi che il Gunebar inviò a Migulma negli anni del 3230-3240 fu la fonte della forza che permise di resistere all'impero Arkhan. " +
            "L'alleanza portò notevoli benefici e i rapporti si consolidarono. Questo permise al Gunebar, dopo la guerra, di usare Migulma come attracco per visitare il continente di Fedora. " +
            "Così dal 3300 in poi il commercio tra i due continenti divenne florido, anche grazie al fatto che il culto dei sette aveva preso piede anche nel continente di Fedora. Il culto infatti era stato promosso " +
            "in Fedora dal sacerdote Opsei già dal secondo millennio, e nel 1339 il culto era gia divenuto la religione più famosa nel centro del continente, e successivamente fu ampiamente accolto anche nel resto di Fedora. ",
        },
      ],
    },
    {
      title: "Popoli di vaniaa",
      shortDescription: "I popoli del continente maledetto.",
      content: [
        {
          type: "title",
          value: "Vaniaa, il continente maledetto, terza Era",
        },
        {
          type: "text",
          value:
            "Vaniaa è un mistero, poco e nulla si sa di quel continente. Se dei popoli lo abitino è incerto. L'unica cosa sicura è che le acque attorno a quelle terre sono maledette. " +
            "Pochi, in tutta la storia scritta sono tornati dopo averla vista. Coloro che non avevano perso il senno, descrivevano enormi strutture abbandonate su cui la natura cresceva " +
            "sovrana. Nella mitologia Dulenviriana, Vaniaa è descritta come un luogo che trasuda veleno, dove ogni passo può essere l'ultimo. Tutti i popoli temono quelle terre che, per " +
            "credenza comune, si dice siano abitate dagli dèi. Durante l'epoca presente, i governi di Korianda e del Dulenvir sostengono di aver subito attacchi provenienti da popoli di " +
            "quelle terre. Tuttavia, nessuna prova concreta esiste a sostegno di tale teoria ed intere flotte sono sparite nella nebbia semplicemente nel tentativo di raggiungere la sponda " +
            "del continente maledetto. La mappa del mondo di Gal Abdur, su cui compaiono anche i confini di Vaniaa, fu il primo ed unico documento che ne dia una rappresentazione geografica accurata. " +
            "Tutte le mappe successive in cui compare il continente di Vaniaa sono solo reinterpretazioni dell'opera originale di Gal Abdur. Egli riuscì non solo a circumnavigare tali terre, ma anche ad accedervi, " +
            "a vagarvi, ed esplorarle.",
        },
        {
          type: "text",
          value:
            "Gal Abdur non era un semplice viaggiatore. Egli era uno dei più grandi esploratori della storia e quando tornò dal continente maledetto, ottenne una grande fama e riconoscenza. " +
            "Alcuni sostennero che aveva trovato una via sicura per accedere al continente, poiché riuscì più di una volta ad andare e tornare, ma egli non diede mai informazioni al riguardo. " +
            "Questa via sicura per il continente di Vaniaa ipotizzata dagli studiosi era nota come la rotta di Gal Abdur. La rotta era e rimane fonte di ispirazione e nutre la bramosia di molti " +
            "cercatori che oggi sperano di essere tra i primi a raggiungere quelle coste nell'epoca moderna. ",
        },
      ],
    },
    {
      title: "Fenili",
      shortDescription: "I clan dei Fenili sparsi per il continente.",
      content: [
        {
          type: "title",
          value: "I barbari di Fedora, i Fenili",
        },
        {
          type: "text",
          value:
            "I Fenili sono un popolo barbaro nomade che si è diffuso in tutto il continente di Fedora a partire dall'anno 542, " +
            "della terza era. In quell'anno giunse al termine una guerra di sterminio mossa da alcuni popoli dell'Olongea limitrofi contro la regione " +
            "del Khan'Powa, una regione montuosa e selvaggia che si estende tra l'Olongea e Migulma. " +
            "Il popolo di quelle terre, oltre venti milioni di persone, fu costretto a fuggire e disperdersi per il continente fuggendo nelle terre selvagge per " +
            "scampare all'odio raziale di cui erano vittime. Nei due secoli successivi il popolo si disperse su tutto il continente, ma i tentativi di integrarsi con le culture" +
            "indigene risultò in fallimenti spesso dalle conseguenze tragiche. ",
        },
        {
          type: "text",
          value:
            "I Fenili vennero rigettati un po' ovunque per la loro diversità, causata anche dalla fiera difesa delle loro usanze e dei loro dei, e spesso furono costretti a vivere in zone remote e selvagge. " +
            "Con il tempo si abituarono alla loro nuova condizione, e iniziarono a vivere in piccoli clan, molte volte in conflitto anche tra loro. " +
            "I Fenili sono un popolo molto bellicoso e alcuni clan sono spesso in lotta fra loro per il controllo delle terre selvagge. " +
            "Altri clan più legati alla tradizione del loro popolo evitano di spargere sangue dei Fenili, e si concentrano a vivere di caccia e di razzie. ",
        },
        {
          type: "text",
          value:
            "Una caratteristica peculiare del loro popolo è la loro capacità di palesarsi in luoghi in cui nessuno li ha mai visti. " +
            "La loro abilità di spostarsi non visti, coprendo anche enormi distanze, è leggendaria. Questa loro dote è legata a un segreto che tutti i clan custodiscono gelosamente " +
            "ed è la ragione per cui, nonostante i molti tentativi di eradicarli, essi continuano a prosperare. ",
        },
        {
          type: "text",
          value:
            "A partire dal periodo tra il 2140 e 2320 della terza era, i Fenili iniziarono a organizzarsi in legioni per fare testa alle difficoltà causate dal progresso delle tecniche usate contro di loro dagli stati di Fedora." +
            "Le legioni Fenili sono veri e propri sistemi di controllo delle macro aree del continente, e tutti i clan sono tenuti a rispettare l'autorità della legione di cui sono membri. " +
            "Nel tempo presente esistono cinque legioni Fenili, comandate dai cinque capi dei clan più potenti. I capi, chiamati anche imperatori dei fenili, sono guerrieri straordinati e sapienti strateghi. ",
        },
      ],
    },
    {
      title: "Non umani",
      shortDescription: "I popoli non umani del mondo",
      sections: [
        {
          title: "Demutani",
          shortDescription: "Esseri umanoidi con le sembianze di animali.",
          content: [
            {
              type: "title",
              value: "Demutani, esseri umanoidi con le sembianze di animali",
            },
            {
              type: "text",
              value:
                "Con il termine Demutani si intendono genericamente tutte le creature selvagge antropomorfe che vivono sparse per le terre selvagge di Fedora. " +
                "Queste creature sono molto diverse tra loro, ma hanno in comune il fatto di avere sembianze umane e animali. " +
                "I Demutani sono creature selvagge e pericolose, che vivono in piccoli gruppi o in solitudine. Sono creature molto territoriali e difendono con ferocia il loro territorio. " +
                "Alcuni di questi evolvono in società più complesse, di stampo tribale, che permette loro di assumere il controllo di territori molto vasti. " +
                "I Demutani sono creature molto intelligenti e astute, e spesso sono in grado di comunicare con gli umani. Alcuni di loro sono in grado di parlare la lingua umana, " +
                "e sono in grado di comprendere i concetti umani. Altri sono in grado di comunicare solo con gesti e suoni. ",
            },
            {
              type: "text",
              value:
                "In generale sono creature molto abili nel combattimento, e spesso sono in grado di uccidere un uomo con un solo colpo. Sono creature molto veloci e agili, e spesso sono in grado di sfuggire alla cattura. " +
                "Rientrano in questa categoria di creature le Phoenie, gli Aviani, i Centauri, gli Aracnotauri e altre creature simili. " +
                "Non è chiara l'origine di questi esseri, ma ne esistono molte varianti, nascoste e isolate, ben lontane dagli uomini che spesso li cacciano per la loro pelle o per la loro carne. ",
            },
          ],
        },
        {
          title: "Servi della tenebra",
          shortDescription: "Esseri dannati e oscuri.",

          content: [
            {
              type: "title",
              value: "Lich, gli stregoni non morti",
            },
            {
              type: "text",
              value:
                "I Lich erano esseri umani che hanno ottenuto l'immortalità attraverso l'uso della magia nera. " +
                "Attraverso un rituale che prevede un giuramento che lega la loro anima a una promessa oscura, e con la scelta di un filatterio, un oggetto magico che contiene la loro anima, " +
                "i Lich sono in grado di vivere per sempre. Il rituale è molto complesso e richiede la morte di molte persone, e spesso il sacrificio di un innocente. " +
                "In generale i Lich Sono creature malvagie e spietate, che hanno rinunciato alla loro umanità per ottenere il potere di dominare alcuni aspetti della realtà. " +
                "I Lich sono creature molto potenti, e spesso sono legati all'opera di Urhari, tutti condividono una folle ambizione che li ha spinti fino a perdere se stessi. " +
                "Non è raro che i Lich sino in grado di controllare eserciti di non morti, la cui dimensione e forza varia a seconda del Lich. ",
            },
            {
              type: "text",
              value:
                "Un Lich è virtualmente indistruttibile. L'unico modo per ucciderlo è quello di distruggere il filatterio, che è l'oggetto magico che contiene la sua anima. " +
                "Senza il filatterio, il Lich muore istantaneamente, per questo tale oggetto viaggia sempre con il Lich stesso e può essere qualunque cosa. " +
                "Nella storia non ci sono stati molti Lich, poichè essi a differenza delle streghe non sono direttamente legati ai dei oscuri. " +
                "Possiedono una quasi totale indipendenza, a patto che le loro azioni non entrino mai in contraddizione con il giuramento fatto. " +
                "Tanto più è vincolante il giuramento, tanto più potente è il Lich. ",
            },
            {
              type: "title",
              value: "Streghe supreme, le streghe spose delle divinità oscure",
            },
            {
              type: "text",
              value:
                "Le streghe supreme sono le streghe che si sono legate in modo definitivo ad un demone maggiore, un dio oscuro, che sia Urhari, Mamellon o Enthial. " +
                "Non sono molto comuni, perchè l'unione a uno dei demoni maggiori prevede un rituale che nel 75% dei casi porta alla morte dell'umano che lo compie. " +
                "La loro corruzione è massima e i loro poteri sono immensi. A seconda del dio oscuro a cui si uniscono, variano i loro modi di agire, i loro desideri e perfino le loro personalità. " +
                "Alcune sono in grado di controllare le menti degli uomini e di creare illusioni. Altre consumano la forza vitale delle persone per continuare a vivere. Altre creano eserciti di non morti. " +
                "Altre ancora sono in grado di creare portali dimensionali per viaggiare tra i mondi.",
            },
            {
              type: "text",
              value:
                "Esse vivono in profonda intimità spirituale con il dio oscuro a cui si sono unite, e possono comunicare con lui in qualsiasi momento. " +
                "Il loro aspetto è spesso mostruoso, con occhi rossi e pelle pallida. Sono spesso vestite di nero e portano con sé un bastone o un'asta. " +
                "Le streghe supreme sono temute e odiate da tutti, e la loro presenza è segno di morte e distruzione. " +
                "Sono spesso al centro di leggende e racconti popolari, e sono spesso descritte come creature malvagie e senza cuore. " +
                "Si dice che quando una di esse viene uccisa, il dio stesso sente il dolore tanto è forte il loro legame.",
            },
            {
              type: "text",
              value:
                "La leggenda narra che le streghe supreme siano state create quasi tutte da Urhari, e solo una piccola parte da Mamellon. Enthial è un dio oscuro che è rimasto in disparte per tutte le ere del mondo, " +
                "e non ci sono tracce di streghe che si siano mai legate a lui.",
            },
            {
              type: "text",
              value:
                "Non è possibile uccidere una strega suprema con metodi convenzionali. Le unichè tecniche che si sono dimostrate efficaci sono principalmente tre. " +
                "La prima è quella di pugnalare il cuore della strega nel giorno di rinascita. Una sola giornata all'anno in cui la strega perde i poteri e diventa vulnerabile. " +
                "La seconda è quella di distruggere il suo bastone, che è il suo legame con il dio oscuro. Senza il bastone, la strega perde i poteri e diventa vulnerabile. " +
                "La terza è quella di distruggere il suo corpo fisico mentre è separato dal corpo spirituale. Quest'ultima è la metodologia più ardua in quanto le streghe supreme sono quasi invulnerabili. ",
            },
            {
              type: "title",
              value: "Streghe maggiori, le spose dei demoni minori",
            },
            {
              type: "text",
              value:
                "Le streghe maggiori sono le streghe che si sono legate in modo definitivo ad un demone minore, figlio di un dio oscuro. " +
                "Sono più comuni delle streghe supreme, ma comunque rare. Il loro potere è minore rispetto a quello delle streghe supreme, ma rimangono molto pericolose. " +
                "Come per le streghe supreme, anche per queste a seconda del demone minore a cui si uniscono, variano i modi di agire, i loro desideri e perfino le loro personalità. " +
                "Le streghe maggiori sono avversari temibili dalla mente contorta e agiscono spesso in modo subdolo e malvagio. " +
                "A differenza delle streghe supreme, che sono in costante contatto con il dio oscuro a cui si legano, le streghe maggiori vivono momenti di indipendenza in cui possono agire in totale autonomia. " +
                "Il loro aspetto è spesso un'alterazione della loro forma fisica naturale, e queste non hanno un bastone da cui traggono il potere. " +
                "Invece, nel loro caso, il demone minore vive direttamente in loro, usando il loro corpo come rifugio nei momenti di riposo. ",
            },
            {
              type: "text",
              value:
                "Le streghe maggiori non sono invulnerabili come le streghe supreme, e i loro cuori sono molto ambiti dai cacciatori di esseri oscuri per le speciali proprietà che sono in grado di infondere in particolari infusi. " +
                "Questa tipologia di strega tende a vivere fino a 300 anni, ma non è raro che alcune di esse raggiungano i 500 anni. " +
                "Al momento della loro morte, il demone minore che le abita si libera e può cercare un nuovo ospite e consuma il corpo della strega, mandandone l'anima all'inferno. ",
            },
            {
              type: "title",
              value: "Streghe minori, le streghe novizie",
            },
            {
              type: "text",
              value:
                "Le streghe minori sono le streghe che si dilettano con i poteri oscuri, senza mai legarsi in modo definitivo ad un demone o un dio oscuro. " +
                "Traggono i loro poteri da rituali oscuri che forniscono effetti temporanei, e spesso si tratta di donne malvage che operano malocchi e maledizioni comuni. " +
                "Sono spesso perseguitate dai cacciatori di esseri oscuri, e la loro vita è molto breve dopo essere scoperte. " +
                "Non sono particolarmente pericolose una volta identificate. Possono essere abbattute con armi comuni.",
            },
            {
              type: "text",
              value:
                "Le streghe minori spesso lavorano in gruppi di tre o quattro, e collaborano per disseminare malefici e causare danni. " +
                "Raramente le si scopre al servizio di streghe maggiori o supreme, delle quali agiscono come faccendiere, spie e informatrici. " +
                "Il più delle volte però la loro poca esperienza le rende facili da individuare e neutralizzare.",
            },
          ],
        },
        {
          title: "Non morti Moakai",
          shortDescription: "I guerrieri non morti dei Moakai",
          content: [
            {
              type: "title",
              value: "I custodi dei terreni di sepoltura",
            },
            {
              type: "text",
              value:
                "I Moakai erano un antico popolo della seconda era che abitava le terre che vanno da Migulma a Korianda." +
                "Erano divisi in molti villaggi disseminati su tutto il territorio, e vivevano in pace con la natura e con gli altri popoli." +
                "Erano un popolo bellicoso, e spesso si trovavano a combattere contro i vicini per risorse e territori." +
                "La loro religione era Shamanica, e veneravano gli spiriti della natura e degli antenati." +
                "Occasionalmente però, li si poteva trovare invischiati in alcuni rituali del culto oscuro. " +
                "Questo popolo fu distrutto dall'epidemia che durante la fine della seconda era devastò il continente di Fedora.",
            },
            {
              type: "text",
              value:
                "I Moakai, prima della loro rovina, avevano sviluppato una tecnica di resurrezione parziale dei morti, che permetteva loro di creare eserciti di guardiani non morti che rimanevano. " +
                "eternamente a guardia dei loro terreni di sepoltura. Questi guardiani non morti, chiamati i sorveglianti, erano guerrieri corazzati e armati di spade e scudi, che combattevano " +
                "contro chiunque osasse profanare le loro terre. I Moakai credevano che i sorveglianti fossero i loro antenati, e li onoravano come tali. " +
                "La presenza di questi eserciti di non morti era un deterrente molto efficace contro i nemici dei Moakai, e spesso bastava la loro presenza per tenere lontani gli invasori. " +
                "Questo ha significato che spesso le loro torre di sepoltura sono state risparmiate dai saccheggi e dalle devastazioni che hanno colpito il resto del continente. ",
            },
            {
              type: "text",
              value:
                "I terreni di sepoltura dei Moakai sono quindi molto ambiti da Collettori e Cercatori, ma raramente hanno avuto successo quando eseguite da poche persone. " +
                "Vista la difficoltà di penetrare nei loro territori, spesso le operazioni nei loro territori sono affidate a intere compagnie, e richiedono un investimento impegnativo di risorse. ",
            },
          ],
        },
      ],
    },
  ],
};
