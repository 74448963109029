import AppLoadingPlaceHolder from "../../AppLoadingPlaceHolder";
import HomeBigBlock from "../../Components/HomeBigBlock/HomeBigBlock";
import RightDataMenu from "../../Components/RightDataMenu/RightDataMenu";
import "./HomePage.scss";

const voices = [
  {
    img: "/Sprites/Erujhan.webp",
    title: "La fuga di Erujhan",
    content:
      "Scopri la storia di Erujhan, un giovane ragazzo che per fuggire dalla guerra si trova in un mondo sconosciuto e deve affrontare le sue paure per poter tornare a casa.",
  },
  {
    img: "/Sprites/Miguel.webp",
    title: "Lore illimitato",
    content:
      "Un mondo di fantasia dove la magia e la tecnologia si fondono per creare un'ambientazione unica e ricca di storie da scoprire. Esplora oltre tre ere di storia ben strutturata, con popoli, religioni, culti e tradizioni.",
  },
  {
    img: "/Sprites/ErujhanLulla.webp",
    title: "Scopri le molte fazioni in gioco",
    content:
      "Incontra personaggi unici e scopri le loro storie, i loro segreti e le loro avventure. I nostri protagonisti dovranno imparare a destreggiarsi tra le varie fazioni in gioco per poter sopravvivere e raggiungere i loro obiettivi.",
  },
  {
    img: "/Sprites/alcana_e_oberon.webp",
    title: "Antagonisti articolati",
    content:
      "Avvicinati ai segreti della misteriosa setta che complotta da decenni per sovvertire l'ordine del paese e accedere ad un potere antico e oscuro. Potrebbe non essere così facile distinguere amici da nemici.",
  },
];

const HomePage = () => {
  return (
    <div className="home-page-outer-wrap">
      <div
        className="home-page-wrap"
        style={{ backgroundImage: "url('assets/home-main-background.webp')" }}
      >
        {voices.map((x: any, i: number) => {
          return (
            <HomeBigBlock
              key={i}
              img={x.img}
              title={x.title}
              content={x.content}
              side={i % 2 !== 0 ? "left" : "right"}
            />
          );
        })}
      </div>
      <div className="home-page-right-zone">
        <RightDataMenu />
      </div>
    </div>
  );
};

export default HomePage;
