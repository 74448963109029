export interface IImageDictionary {
  imageUrl: string;
  imageUrlGif?: string;
  imageMetadata: string;
  imageTitle: string;
  isCanonForCharacter?: boolean;
  imageStyle: "anime" | "illustrazione" | "realistico";
}

const ImageDictionary: IImageDictionary[] = [
  {
    imageUrl: "0e95a523-0298-4e8e-8b2b-e0d07cd483fd",
    imageMetadata: "erujhan, kahel, fuoco",
    imageTitle: "L'adolescente Erujhan usa il fuoco Kahel",
    imageStyle: "anime",
  },
  {
    imageUrl: "0a4e14ae-5903-40e0-9b52-e9710e86c721",
    imageMetadata: "siduria, oberon",
    imageTitle: "Siduria e Oberon, accampati nel bosco",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "05e79727-544a-4a9b-99de-ab4bc53f0e94",
    imageMetadata: "divino, kilmitaur",
    imageTitle:
      "Divino Khilmitaur: camminatore di sogni e protettore dei pazzi e dei disabili di ogni tipo",
    imageStyle: "realistico",
  },
  {
    imageUrl: "c15dcc1c-a335-47d1-8043-250c4e3c93fb",
    imageMetadata: "umbri",
    imageTitle:
      "Il titanico re lich, Umbri, servo fedele del dio oscuro Mamellon.",
    imageStyle: "realistico",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "3741b207-26d0-4dbf-8f37-e151e6ea3bd2",
    imageMetadata: "beren",
    imageTitle:
      "Beren travolta dal timore di essere tradita, considera la fuga.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "5d8cc7d4-11ea-4bed-96d8-e4f0b3b003db",
    imageMetadata: "selene",
    imageTitle: "Selene posa nel lago Tuluk, dietro il bosco di chiaravalle.",
    imageStyle: "anime",
  },
  {
    imageUrl: "5e7176f5-9748-4d6b-90d1-9dfc592cc60d",
    imageMetadata: "selene",
    imageTitle: "Selene posa nel lago Tuluk, dietro il bosco di chiaravalle.",
    imageStyle: "anime",
  },
  {
    imageUrl: "57fbc81b-8328-4338-8403-b21b1f1e3c7f",
    imageMetadata: "selene",
    imageTitle: "Selene posa nel lago Tuluk, dietro il bosco di chiaravalle.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "75962fe3-9b82-4beb-bc33-ae4bfe8f7b98",
    imageMetadata: "kaori, asami",
    imageTitle: "Kaori e Asami nella libreria della villa Riftikye",
    imageStyle: "anime",
  },
  {
    imageUrl: "a12b34c9-f5a8-45cc-9dd0-2d507bf63845",
    imageMetadata: "kaori, asami",
    imageTitle: "Kaori e Asami nella libreria della villa Riftikye",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "b1df9085-b0e9-4fa5-94eb-ca23b65d202e",
    imageMetadata: "selene",
    imageTitle: "Selene passeggia nel bosco di chiaravalle.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "d8e1a1b3-ee32-4845-9587-561a033abc3d",
    imageMetadata: "selene",
    imageTitle: "Selene posa nel lago Tuluk, dietro il bosco di chiaravalle.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "056fa925-70a2-4c7e-af62-a13d74f60436",
    imageMetadata: "divino, kilmitaur",
    imageTitle:
      "Divino Khilmitaur: camminatore di sogni e protettore dei pazzi e dei disabili di ogni tipo",
    imageStyle: "realistico",
  },
  {
    imageUrl: "33b8f181-a93e-447e-9ded-49bf4201623e",
    imageMetadata: "divina, hurna",
    imageTitle:
      "Divina Hurna: custode della rinascita e della guarigione, protettrice delle madri",
    imageStyle: "realistico",
  },
  {
    imageUrl: "a5c3975f-c1fd-46b0-989e-882c5fde1fef",
    imageMetadata: "divina, hurna",
    imageTitle:
      "Divina Hurna: custode della rinascita e della guarigione, protettrice delle madri",
    imageStyle: "realistico",
  },
  {
    imageUrl: "63342f34-3a8f-43d5-9bc1-f58524601617",
    imageMetadata: "divina, querrell",
    imageTitle:
      "Divina Querrell: pacificatrice delle controversie e ispiratrice di clemenza, protettrice degli orfani",
    imageStyle: "realistico",
  },
  {
    imageUrl: "a4e2c307-0a0e-44c5-8bb0-8a9e8e13dc19",
    imageMetadata: "divina, querrell",
    imageTitle:
      "Divina Querrell: pacificatrice delle controversie e ispiratrice di clemenza, protettrice degli orfani",
    imageStyle: "realistico",
  },
  {
    imageUrl: "99ae3ec1-92b5-4952-9eae-feed90f79ffd",
    imageMetadata: "divino, arzik",
    imageTitle:
      "Divino Arzik: portatore di fortuna e forgiatore di intelligenza, protettore degli artisti",
    imageStyle: "realistico",
  },
  {
    imageUrl: "732c40be-014d-479d-9750-8c23ceab3cd3",
    imageMetadata: "divino, arzik",
    imageTitle:
      "Divino Arzik: portatore di fortuna e forgiatore di intelligenza, protettore degli artisti",
    imageStyle: "realistico",
  },
  {
    imageUrl: "d98ba85f-7c49-46c5-8075-3c4e18aca4c3",
    imageMetadata: "divino, tolkanigan",
    imageTitle:
      "Divino Tolkanigan: il guardiano delle forze della natura, protettore degli animali",
    imageStyle: "realistico",
  },
  {
    imageUrl: "d078964c-da3e-4295-8c12-d424f6894f09",
    imageMetadata: "divino, tolkanigan",
    imageTitle:
      "Divino Tolkanigan: il guardiano delle forze della natura, protettore degli animali",
    imageStyle: "realistico",
  },
  {
    imageUrl: "9939b430-6b1d-4740-b90c-9a1d9ab4f29a",
    imageMetadata: "divina, shelmalakia",
    imageTitle:
      "Divina Shelmalakia: rivelatrice di segreti e liberatrice degli ingannati, protettrice dei cercatori.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "fea3aac1-9ecd-4504-a172-028373528bee",
    imageMetadata: "divina, shelmalakia",
    imageTitle:
      "Divina Shelmalakia: rivelatrice di segreti e liberatrice degli ingannati, protettrice dei cercatori.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "e2bc1e67-6643-4128-a056-70b35925b7bf",
    imageUrlGif: "0a4e14ae-5103-40e0-9b52-e9711e86c72e",
    imageMetadata: "laurelian, lulla",
    imageTitle:
      "Laurelian tenta degli esperimenti di alchimia nel laboratorio del padre.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "e1aa8520-da4e-49ac-beb4-d6cdbf581663",
    imageMetadata: "divino, lantamos",
    imageTitle:
      "Divino Lanthamos: Signore dell'eredità e della tradizione, protettore degli innocenti",
    imageStyle: "realistico",
  },
  {
    imageUrl: "fc47bde3-fe74-43a2-b07a-258e77b9f1d3",
    imageMetadata: "divino, lantamos",
    imageTitle:
      "Divino Lanthamos: Signore dell'eredità e della tradizione, protettore degli innocenti",
    imageStyle: "realistico",
  },
  {
    imageUrl: "67b9e2ab-0ed8-4ed7-b693-a1abe0a27be5",
    imageMetadata: "fare",
    imageTitle: "Un personaggio curioso tutto da scoprire",
    imageStyle: "realistico",
  },
  {
    imageUrl: "3154b725-44d0-4c5f-95a2-59c6c9136824",
    imageMetadata: "fare",
    imageTitle: "Un personaggio evocatore e arciere tutto da scoprire",
    imageStyle: "anime",
  },
  {
    imageUrl: "40adba74-06df-44b5-81ac-8c3651cb351b",
    imageMetadata: "fenice, grotta, nido",
    imageTitle: "Il nido di Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "8ea577fc-b191-41d7-9cf3-a4834c583e78",
    imageMetadata: "fenice, grotta, nido",
    imageTitle: "Il nido di Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "52dd1e76-cb88-47d1-b2f1-7afaa7f03352",
    imageMetadata: "fena, grotta, nido",
    imageTitle: "Il nido di Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "186048fc-e03a-450b-a0a6-016fb59c26de",
    imageMetadata: "fena, grotta, nido",
    imageTitle: "La bambina Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "a1e62226-1529-4c0f-95b9-ad2d5162a565",
    imageMetadata: "fena, grotta, nido",
    imageTitle: "La bambina Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "e80f2306-3e97-4333-9da8-a0615ef38402",
    imageMetadata: "fenice, grotta, nido",
    imageTitle: "La bambina Phoena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "5eae60c5-157e-4e0e-8c7e-ce7631858084",
    imageMetadata: "fena regina, grotta, nido",
    imageTitle: "Regina Fena all'interno della grotta nascosta",
    imageStyle: "realistico",
  },
  {
    imageUrl: "5dcaee65-4fb8-4e58-8b90-8ab55484e585",
    imageMetadata: "kaori, barone haakon riftikye",
    imageTitle:
      "Kaori libera il suo vero potere, uccidendo per errore il barone Haakon Riftikye",
    imageStyle: "anime",
  },
  {
    imageUrl: "c6cda969-99d5-472e-b307-bab0eefa396b",
    imageMetadata: "kaori",
    imageTitle: "Kaori posa nella sala principale della Riftikye Ville",
    imageStyle: "realistico",
  },
  {
    imageUrl: "3e1848cb-eba7-479b-bab8-39599836781b",
    imageMetadata: "dustel",
    imageTitle: "Dustel lavora nel suo laboratorio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "c24f8ef9-cc5f-441d-8a5d-35fa08104246",
    imageMetadata: "dustel",
    imageTitle: "Dustel lavora nel suo laboratorio",
    imageStyle: "anime",
  },
  {
    imageUrl: "5e2aadea-55c7-4294-8b50-c6f3939d6256",
    imageUrlGif: "0a4e14ae-5903-40e0-9b52-e9711e86c72e",
    imageMetadata: "erujhan, kahel, fuoco",
    imageTitle: "L'adolescente Erujhan usa il fuoco Kahel",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "208e0b70-f4dd-4fa4-9001-f23675090364",
    imageMetadata: "beren, silirus",
    imageTitle: "Beren e Silirus fuggono dalla villa",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "8c0c0a6f-98a1-4433-a701-5ab4c6e8cc9e",
    imageMetadata: "sharnel",
    imageTitle: "Sharnel in crociera d'affari",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "68635663-6062-4398-98e7-252d5346d2ef",
    imageMetadata: "Dustel, Erida, Laurelian, Erujhan, Dustel, Dungeon",
    imageTitle: "Dustel, Erida, Laurelian ed Erujhan in una prigione",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "a4cc1b07-9474-4f65-9e4b-355dc2fc7f14",
    imageMetadata: "sharnel, miguel, umarsh",
    imageTitle: "Sharnel e Miguel a Umarsh",
    imageStyle: "anime",
  },
  {
    imageUrl: "4a3ce284-a149-49f3-9108-a2d30b5145a7",
    imageMetadata: "miguel, prigione",
    imageTitle: "Miguel esplora una prigione",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "70a3beac-776f-43c7-a239-01933e621fe7",
    imageMetadata: "erida, apochoros",
    imageTitle: "Erida fa surf su una creatura evocata utilizzando l'Apochoros",
    imageStyle: "anime",
  },
  {
    imageUrl: "4cbc8e83-8888-4464-bde5-ea10832459d8",
    imageMetadata: "lulla, laureliana",
    imageTitle: "Laurelian in tenuta da mercenario",
    imageStyle: "anime",
  },
  {
    imageUrl: "f2093fc1-8a6e-49cb-b953-d74895bd2db1",
    imageMetadata: "Oberon",
    imageTitle: "Il capitano Oberon coperto dal suo mantello",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "74302973-8e7a-4579-8337-82c1f6a49e64",
    imageMetadata: "lucian,mya",
    imageTitle: "Lucian e Mya in un momento di relax",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "d9214862-2476-43e1-8943-cd2dcc235ffa",
    imageMetadata: "dottor F",
    imageTitle: "Il dottor F nel suo laboratorio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "d487a2a1-e4ba-47fc-992a-342aeae34b7c",
    imageMetadata: "Oberon",
    imageTitle: "Il capitano Oberon coperto dal suo mantello",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "ffeb0f0c-7839-4dd3-988b-6e23ab8234e1",
    imageMetadata: "Oberon",
    imageTitle: "Il capitano Oberon coperto dal suo mantello",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "b59a369f-c63a-42ad-a2e8-00e4384519b8",
    imageMetadata: "lane",
    imageTitle: "Comandante Lane in capo di un'operazione",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "0a39f600-b6ce-4fe7-83d3-08412de48869",
    imageMetadata: "julien sullivan grace",
    imageTitle: "Ammiraglio Julien Sullivan Grace sul ponte della sua nave",
    imageStyle: "anime",
  },
  {
    imageUrl: "4fabd75c-6771-4077-a12b-2bb73d0cae2e",
    imageMetadata: "julien sullivan grace",
    imageTitle: "Ammiraglio Julien Sullivan Grace sul ponte della sua nave",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "ba989efe-d306-49da-9c6c-178b10268e59",
    imageMetadata: "julien sullivan grace, razenna",
    imageTitle:
      "Grace aggredito da Razenna nel tempio Laosh del portale di Fedora",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "6f7b711c-4d4f-4ab6-86ea-6ea9a76e42a5",
    imageMetadata: "sebastian raul grace",
    imageTitle:
      "Ammiraglio capo della marina di Kodianda e capo di stato maggiore Sebastian Raul Grace, padre di Julien Grace",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },

  {
    imageUrl: "e068f022-5ea8-4da2-9ed3-fc72c615d1bd",
    imageMetadata: "esse, pentagono",
    imageTitle: "Il Pentagono di Esse",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "49558661-08ce-4a78-bf8b-cf6e8b0dbce3",
    imageMetadata: "lulla, laureliana",
    imageTitle: "Mercenario Laurelian",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "a914556e-1bf4-4ed8-8930-7d5944f417fe",
    imageMetadata: "kaori",
    imageTitle: "Kaori nel giardino della villa",
    imageStyle: "anime",
  },
  {
    imageUrl: "a9b69bed-ab0a-4daf-9fcf-7c891891e98c",
    imageMetadata: "erujhan, hedemora",
    imageTitle: "Erujhan seduto su una collina, vicino a Hedemora.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "d2257352-2814-42d6-aea5-14fbd2cdb21d",
    imageMetadata: "Dustel, leviguscio",
    imageTitle: "Dustel sul leviguscio",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "da6bcd8e-208f-45fd-bd5d-51fab2f1d527",
    imageMetadata: "miguel, sharnel, umarsh",
    imageTitle: "Miguel e Sharnel a Umarsh",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "f8a381d4-3d70-42e7-a99a-24a58dfba2f5",
    imageMetadata: "miguel, sharnel, umarsh",
    imageTitle: "Miguel e Sharnel a Umarsh",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "d6dd2ab1-a762-43a9-98d5-6488c9dbb872",
    imageMetadata: "erujhan, hedemora",
    imageTitle: "Erujhan seduto su una collina, vicino a Hedemora.",
    imageStyle: "realistico",
  },
  {
    imageUrl: "f051a4d0-6d76-48dd-bd32-26ba277c251c",
    imageMetadata: "erujhan, hedemora",
    imageTitle: "Erujhan seduto su una collina, vicino a Hedemora.",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "f66c6239-8206-4c45-b415-5270c3665168",
    imageMetadata: "hiuan",
    imageTitle: "Il giovane Hiuan si allena nel tempio",
    imageStyle: "realistico",
  },
  {
    imageUrl: "4cdce3a7-684d-4d5d-a3a1-88b4af9412f5",
    imageMetadata: "lulla, laureliana",
    imageTitle: "Mercenario Laurelian",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "ffeb0f0c-7839-4dd3-988b-6e23ab8234e7",
    imageMetadata: "olar",
    imageTitle: "Olar scava una buca per assolvere gli ordini di Miguel",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "4f235db0-7dce-476b-8300-d5b46d3b1aac",
    imageMetadata: "erida",
    imageTitle: "Erida sorride sul ponte della nave",
    imageStyle: "realistico",
  },
  {
    imageUrl: "7750da5d-b14f-4f59-a78b-30472787a1fe",
    imageMetadata: "erida",
    imageTitle: "Erida sorride sul ponte della nave",
    imageStyle: "realistico",
  },
  {
    imageUrl: "2628253b-5fbd-4a85-869c-1c72a4b9a981",
    imageMetadata: "erida",
    imageTitle: "Erida sorridente vicino alla nave",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "f2d07aa2-37cc-42dd-8a37-afd8fb515a83",
    imageMetadata: "daitan",
    imageTitle: "La Daitan, la nave volante di Miguel",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "f2093fc1-8a6e-49cb-b953-d74895bd2db8",
    imageMetadata: "daitan",
    imageTitle: "La Daitan, la nave volante di Miguel",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "e60605aa-3cef-4eef-8ab9-b43e415de54e",
    imageMetadata: "miguel, daitan",
    imageTitle: "Miguel sul ponte Daitan",
    imageStyle: "realistico",
  },
  {
    imageUrl: "e1c08154-7ecf-48a0-ae03-a2dc9b0afdee",
    imageMetadata: "erujhan, libro hara",
    imageTitle: "Erujhan legge il libro Hara",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "dbae38b5-de77-49b2-b2b2-c8b1e42d3461",
    imageMetadata: "erida",
    imageTitle: "Erida sorridente vicino alla nave",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "6689250a-f71e-40c5-9f1a-d5075db00cca",
    imageMetadata: "miguel",
    imageTitle: "Faccia anteriore di Miguel",
    imageStyle: "anime",
  },
  {
    imageUrl: "f3fc8409-4266-4289-8b2a-7d9f5ff125e5",
    imageMetadata: "miguel",
    imageTitle: "Faccia anteriore di Miguel",
    imageStyle: "illustrazione",
  },
  {
    imageUrl: "b4adbd55-02aa-4976-a5db-79f98a906ad9",
    imageMetadata: "miguel",
    imageTitle: "Faccia anteriore di Miguel",
    imageStyle: "anime",
  },
  {
    imageUrl: "5c4930ca-6b67-45f2-9592-e9fec6dd18cc",
    imageMetadata: "miguel, daitan",
    imageTitle: "Miguel nella sala di controllo della sua nave, la Daitan",
    imageStyle: "realistico",
  },
  {
    imageUrl: "460edf03-a9fe-4034-90e3-e4ebe2d81eab",
    imageMetadata: "Miguel, Kaori",
    imageTitle: "Miguel con Kaori nel giardino della villa.",
    imageStyle: "anime",
  },
  {
    imageUrl: "728de07d-7bdc-46f2-aae8-478869c1e012",
    imageMetadata: "hanteki,hoku",
    imageTitle:
      "Hanteki gattona vicino alla sua casa fiorita, assieme al cane di Selene, Hoku.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "0a4e14ae-5903-40e0-9b52-e9710e86c72e",
    imageMetadata: "fare",
    imageTitle: "SuperSoldato nella sala di addestramento.",
    imageStyle: "anime",
  },
  {
    imageUrl: "75e3fa3d-df76-49d8-85bf-83e623308c8e",
    imageMetadata: "Yomu",
    imageTitle:
      "Yomu, fantasma della lama di vento, in piedi al centro di un tempio Moakai.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "0b87f31f-65d5-4fd9-a390-55544c256db3",
    imageMetadata: "laureliano",
    imageTitle:
      "Il ragazzino di Laurelian che fa alcuni esperimenti di alchimia",
    imageStyle: "anime",
  },
  {
    imageUrl: "2a92fb85-4971-4c13-b2af-cf254b0f4135",
    imageMetadata: "erida",
    imageTitle: "Erida sorride",
    imageStyle: "anime",
  },
  {
    imageUrl: "6844d523-5dd3-41e1-ad14-40e49a439b11",
    imageMetadata: "Yulia",
    imageTitle: "Yulia Helzeidama",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "d5dcb15e-e89b-42c5-aac4-dd0456001f59",
    imageMetadata: "lotis, pascale",
    imageTitle: "Lotis e Pascale al lavoro in ufficio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "9df55210-9ae8-410d-91ca-3a3b797bbcaa",
    imageMetadata: "erida",
    imageTitle: "Erida sorride",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "7e5e7c31-cf3f-47b1-8d53-99676891ddfc",
    imageMetadata: "larion",
    imageTitle:
      "Larion Ramnisk comodamente seduto nel suo soggiorno, studiando il diario del Coshellar mentre beve birra.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "b70ebf10-106f-4671-9d67-7f0e00783535",
    imageMetadata: "ida",
    imageTitle:
      "Ida Swath, nonna di Erujhan, si prende comodamente cura del suo giardino.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },

  {
    imageUrl: "10a562c4-9746-451c-a2f1-13b34bde706d",
    imageMetadata: "zhurum",
    imageTitle: "Zhurum, la cupola nel cielo, tempio dei sette sommi sacerdoti",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "da3f1923-b3cc-4146-b88f-feba97a88e47",
    imageMetadata: "hikaard saulo",
    imageTitle:
      "Re Hikaard Saulo, il re di Amrath, dopo la conquista di una città ribelle",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },

  {
    imageUrl: "8b5c20d3-0237-45a7-953c-af252c311d96",
    imageMetadata: "irene",
    imageTitle: "Irene Ramnisk in mezzo al caos della cucina.",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "bf1b7e19-7cf6-40f9-82d0-d50d7c888158",
    imageMetadata: "sharnel",
    imageTitle: "Sharnel Pitò, mentre lavora nel suo ufficio sul suo portatile",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "bd9d19b6-025b-4f42-9548-4d7c751ce126",
    imageMetadata: "razenna la strega",
    imageTitle:
      "La strega maggiore Razenna, nella selva attorno la città perduta di Sekurmina",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "800059a7-5683-4d9a-82b0-ac0aae374515",
    imageMetadata: "hiuan, erujhan",
    imageTitle: "Il Maestro Hiuan Uruua ed Erujhan nel giardino di allenamento",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "f5c38d87-5732-4c0d-823d-2472832b899b",
    imageMetadata: "Eden",
    imageTitle: "Eden Larse, passeggiando felicemente in periferia",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "d823e236-4b78-448b-b44e-3335b99d08fd",
    imageMetadata: "naro",
    imageTitle: "Naro Quins al lavoro sui terminali dei Veglianti",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "2659f7db-41b2-47de-a6b8-70e9faa46195",
    imageMetadata: "lilith",
    imageTitle: "Lilith, la cacciatrice di streghe, in un momento di relax",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "df785391-9beb-4051-b3c5-e21bb50818b3",
    imageMetadata: "miguel",
    imageTitle: "Miguel alla ricerca di reperti",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "27732e74-893d-4463-8bb3-c4a949111d09",
    imageMetadata: "githwick, villar",
    imageTitle: "Githwick e Villar davanti al faro di Diviré",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "951123ed-1092-47f2-80da-3ddef60de2b1",
    imageMetadata: "aldren lu'crash",
    imageTitle: "Aldren Lu'Crash in una sede dei Veglianti",
    imageStyle: "anime",
  },
  {
    imageUrl: "fb52b62a-f374-4071-a456-a4af8417b655",
    imageMetadata: "aldren lu'crash",
    imageTitle: "Aldren Lu'Crash in una sede dei Veglianti",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "c976398a-d1cd-4a08-8780-4cca7fac37ce",
    imageMetadata: "urhari",
    imageTitle: "Oscuro Urhari: il distruttore e signore delle folli ambizioni",
    imageStyle: "realistico",
  },
  {
    imageUrl: "762e543f-9ca0-40ce-a8f5-0482ba2abc7d",
    imageMetadata: "mamellon",
    imageTitle:
      "Oscuro Mamellon: il corruttore e padrone delle anime perdute, dominatore degli eserciti dannati",
    imageStyle: "realistico",
  },
  {
    imageUrl: "3f13c264-8cb4-4b08-a56f-d7bf922b47ef",
    imageMetadata: "enthial",
    imageTitle:
      "Oscura Enthial: la dissacratrice, autrice di tradimenti e regina delle bugie",
    imageStyle: "realistico",
  },
  {
    imageUrl: "8527f6e8-b9b2-4e68-b210-54e3b33d4722",
    imageMetadata: "sinikka",
    imageTitle:
      "Sinikka Simmus, cercatrice collega e rivale di Miguel, mentre esplora un vecchio tempio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "d6938c9d-c3bf-4922-9a72-2566f735db0c",
    imageMetadata: "maverik coleman",
    imageTitle: "Maverik Coleman, magistrato del Craviro, nel suo studio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "41c460fa-aa94-4b0a-a3e0-eaca727ce21f",
    imageMetadata: "siduria rolov",
    imageTitle: "Siduria Rolov, cercatore rinnegato ora in squadra con Oberon",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "939b6a33-2826-4dc9-98ee-1b37c4b15634",
    imageMetadata: "anister colsireus",
    imageTitle:
      "Anister Colsireus, grand'araldo dei collettori del tempo presente",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },

  {
    imageUrl: "e5971316-9afc-4c62-8a86-e3eb9e271b1b",
    imageMetadata: "vikarus edailama",
    imageTitle: "Vikarus Edailama, il monaco oscuro",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "1f75e9c5-bab4-4153-9a37-8ec4fd27f07d",
    imageMetadata: "vikarus edailama",
    imageTitle: "Vikarus Edailama, il monaco oscuro",
    imageStyle: "anime",
  },
  {
    imageUrl: "f90d2bfc-d0e4-4cea-a291-fdf5124f4613",
    imageMetadata: "Ulrandir",
    imageTitle:
      "Ulrandir; re delle collone, uno dei cinque imperatori dei Fenili",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "634be3ab-97b9-4335-838d-d954fcab9c2b",
    imageMetadata: "alcana belle",
    imageTitle: "Strega suprema Alcana Belle, viaggia solitaria",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "e914556e-1bf4-4ed8-8930-7d5944f417fe",
    imageMetadata: "alcana belle",
    imageTitle:
      "Strega suprema Alcana Belle in una grotta che organizza un rituale",
    imageStyle: "anime",
  },
  {
    imageUrl: "ae0be80ff-28d3-438c-9ec6-aaa6d48b9dc1",
    imageMetadata: "alcana belle",
    imageTitle: "Strega suprema Alcana Belle, concetto alternativo",
    imageStyle: "anime",
  },

  {
    imageUrl: "8062cc57-5b4f-4d45-bc0c-afce699252d5",
    imageMetadata: "murha busham",
    imageTitle:
      "Murha Busham, il padre di Lulla, a lavoro nella fattoria del villaggio",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "ae0be80ff-28d3-438c-9ec6-aaa6d48b9dc6",
    imageMetadata: "khemnee girtha",
    imageTitle: "La città perduta di Khemnee Girtha, la vedetta del nord",
    imageStyle: "realistico",
  },
  {
    imageUrl: "7c2e5a3c-e14d-4b94-9d7c-7b9d6a08c7a2",
    imageMetadata: "generale selvedin bukhard",
    imageTitle:
      "Generale Selvedin Bukhard, capo di stato maggiore della difesa di Korianda e cofondatore della CRAPBA",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "9070ac5c-47f7-4266-b40e-d0c9cbbe72fc",
    imageMetadata: "lumopalmi",
    imageTitle: "Un gruppo di lumopalmi in caccia",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },

  {
    imageUrl: "df387757-16bd-47bf-86db-8d2ee0dc96c2",
    imageMetadata: "raw tallman",
    imageTitle:
      "Raw Tallman, cercatore collega di Miguel, al lavoro in un dungeon",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "4531008f-e0f8-4c4f-902f-13e6b3b72d06",
    imageMetadata: "alvira revook",
    imageTitle: "Alvira Revook, ex mercenario, ora killer a pagamento",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "45f9140b-205c-417f-bb7c-7be2ef0acce6",
    imageMetadata: "zhar hulma",
    imageTitle:
      "Zhar Hulma, principe di Snelia, a passeggio nel boschetto attorno al suo palazzo",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "f44c43ab-3a68-4739-ab3a-25219967513c",
    imageMetadata: "chagatai, anarchia",
    imageTitle:
      "Guerriero non morto Chagatai, suprannome Anarchia, al termine di un lavoro",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
  {
    imageUrl: "1407d21a-d478-4d5e-b7fe-defb9e33bf3b",
    imageMetadata: "ravel shnaide, amanda clasté, deivi lasta",
    imageTitle:
      "Amanda Clasté, Ravel Shnaide e Deivi Lasta in una delle loro sontuose ville",
    imageStyle: "anime",
    isCanonForCharacter: true,
  },
];

var ShuffledArray = ImageDictionary.sort(() => Math.random() - 0.5).map(
  (x: IImageDictionary) => {
    x.imageMetadata = x.imageMetadata.replaceAll(" ", "").toLocaleLowerCase();
    return x;
  }
);

export const GetCanonImage = (imageKey: string) => {
  imageKey = imageKey.toLocaleLowerCase().replaceAll(" ", "");
  let image: IImageDictionary | undefined = ImageDictionary.find(
    (image: IImageDictionary) =>
      image.isCanonForCharacter && image.imageMetadata.startsWith(imageKey)
  );

  if (image) {
    if (image.imageUrlGif) {
      return "./AnimatedGallery/" + image.imageUrlGif + ".gif";
    }

    return "./Gallery/" + image.imageUrl + ".webp";
  }

  return "";
};

export default ShuffledArray;
