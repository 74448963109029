export const Wall = {
  ERUJHAN: [
    "È il nostro protagonista. Nato nel 4111 della terza era, la sua avventura inizia nell'estate dell'anno 4124, poco dopo il suo tredicesimo compleanno. Lo incontriamo nel piccolo paesino rurale di Hedemora, situato nel sud dello stato di Korianda.",
    "Il giovane però non è originario di quel luogo. Egli è nato in un paesino poco più ad ovest, chimato il borgo di Prist, che ha dovuto abbandonare all'età di dieci anni a causa di una tragedia familiare.",
    "Il padre era da poco scomparso al fronte in modo misterioso, e da allora è ricercato dalle autorità del paese come disertore. La madre di Erujhan, Irene, non riuscendo a sopportare la pressione economica in cui si trovava, si è trasferita con i figli a Hedemora per vivere come opsite a casa del padre.",
    "I nonni di Erujhan, Larion e Ida, adorano avere i nipoti con se e si prendono cura di loro come se fossero i loro figli.",
    "Erujhan è il fratello minore ed è un ragazzo molto intelligente e sveglio, ma anche molto chiuso e riservato. Nonostante la sua giovane età, è molto responsabile e si prende cura dei suoi studi, più che altro per rendere orgogliosa la madre.",
    "Il fratello maggiore, Dorian, è anche lui partito da un anno per servire il suo paese nella guerra di quel tempo. Purtroppo questà è una guerra che non accenna a terminare.",
    "Presto sarebbe giunto il momento in cui l'esercito avrebbe arruolato anche Erujhan e il nonno, non sopportando l'idea di perdere anche l'ultimo nipote, si ingegna per far si che il ragazzo possa tentare un'azzardato piano di fuga.",
    "Questo è l'inizio della sua avventura.",
  ],
  LARION: [
    "Il giocoso e astuto nonno di Erujhan. Larion è un uomo che ama la vita tranquilla e la sua famiglia. Nonostante la sua età, è ancora molto attivo e si prende cura di tutto ciò che riguarda la casa.",
    "È un uomo molto saggio e intelligente, e ha sempre un consiglio da dare al nipote, anche se non sempre è quello che Erujhan vorrebbe sentire. Nonostante sia un uomo molto rispettato nel suo paese, e tutti lo conoscono come un uomo molto onesto e leale, ha un passato molto oscuro che non ha mai rivelato a nessuno, alcuni aspetti nemmeno alla moglie.",
    "Egli ha avuto un infanzia per certi versi difficile, suo padre Ivan era severo e autoritario. La madre, invece, era una donna molto dolce e amorevole. Dopo la morte di lei, la famiglia iniziò a girovagare nel mondo per il lavoro di Ivan.",
    "Il padre infatti era un Collettore, un membro appunto della confraternita dei Collettori, un'organizzazione che si occupa di recuperare oggetti e informazioni per evitare che i tremendi e potenti tesori del passato cadessero in mani empie. Questa organizzazione era molto rispettata e temuta in tutto il mondo, e i suoi membri sono molto ben addestrati e preparati.",
    "Quando ancora Larion era ragazzo, i Collettori vennero attaccati in tutte le loro sedi in un formidabile assalto, e molti di loro persero la vita. Ivan e Larion al tempo si salvarono per pura fortuna. Avendo perso tutto, Ivan decise spostarsi in un paese lontano, dove nessuno li avrebbe mai trovati.",
    "Così giunsero in Korianda, nel paesino di Hedemora. Qui Larion incontro la futura moglie, Ida, e presto ebbero la loro unica figlia, Irene. Larion decise di non rivelare nulla del suo passato a nessuno, e di vivere una vita tranquilla e serena, lontano da ogni pericolo.",
    "Questo almeno, fu vero fino a che non divenne necessario scoprire le sue carte per salvare il nipote.",
  ],
  LAURELIAN: [
    "Laurelian, per gli amici Lulla, è la prima vera amica di Erujhan. Nata nel 4112 della terza era, è immigrata da piccola dall'Olongea, la congrega dei paesi del sud, è una ragazza molto socievole e intraprendente, ma anche sensibile, amante della natura, con un talento quasi geniale per le ricette e la cucina.",
    "Ha perso la madre quando e venuta al mondo, e dopo essersi trasferita a Hedemora con solo il padre, il signor Murha, ha vissuto alcuni difficili per integrarsi. Questo a causa della diffusa cultura carica di pregiudizio che in quegi paesini di frontiera ancora vigeva con forza.",
    "Seppur non fosse cresciuta nel benessere economico, il suo spirito era allegro e curioso. La compagnia che non riusciva a trovare nelle persone la cercava nei suoi amici animali, ai quali si affezionava molto facilmente.",
    "Durante un fortuito incontro, la giovane ha conosciuto Erujhan e i due si sono subito affiatati. Lei fu commossa dallo scoprire che c'era qualcuno che non la odiava solo per le sue origini straniere, e questo ha reso il suo rapporto con Erujhan molto speciale.",
    "Quando Erujhan è stato costretto a fuggire dalla guerra tramite il piano ideato da suo nonno, Lulla ha deciso di seguirlo. Essere ammessa nel piano di fuga non sarebbe stato facile, avrebbe dovuto dimostrare il suo valore, e questo le è costato un arduo periodo di addestramento come alchimista e chimica, addestramento di cui si è curato il padre.",
    "Il padre di lei, il signor Murha, un omone forte dal cuore gentile, nel suo paese d'origine era un talentuoso studioso di arti alchemiche e questa sua capacità gli aveva permesso di ottenere un lavoro come chimico in una delle fabbriche di Hedemora.",
    "Così, quando giunge il tempo della fuga, i due giovani si alleano e insieme hanno iniziato un viaggio che li porterà a scoprire molte cose sul mondo e su se stessi.",
  ],
  MIGUEL: [
    "Il misterioso Miguel, capitano della nave volante chiamata Daitan, è un esperto Cercatore. Di tanto in tanto lo si vede atterrare nel paesino di Hedemora per incontrare alcuni suoi cari amici che vivono in periferia.",
    "In quanto Cercatore, egli nella vita viaggia come una sorta di esploratore mercenario. Parte alla ricerca di tesori e informazioni che rivende ai suoi committenti. È un uomo molto abile e coraggioso, ma anche molto paziente e meticoloso.",
    "Fuggito da piccolo dalla sua terra natale, il paese di Migulma, è stato cresciuto dai pochi superstiti del suo villaggio, distrutto durante un assalto di un clan rivale. Durante l'adolescenza viene a sapere che sua sorella, dispersa anche lei, è ancora viva.",
    "Con l'aiuto dei suoi amici si fa addestrare nell'antica arte del Dharma Talgalla, e presto ne diventa un maestro. Con questa nuova abilità, e con la Daitan ricevuta in dono, Miguel parte e diventa presto un Cercatore, così da poter avvalersi della rete di contatti e informazioni che appartengono a quella gilda nella speranza di rintracciare la sorella.",
    "Con lui viaggia solo il suo fidato compagno, Olar, un armaturra magica tenuta in vita da meccanica antica di cui Miguel ignora il funzionamento. Eppure, con l'aiuto di Olar, riesce a farsi strada nel duro mondo dei Cercatori, ottenendo perfino una licenza di alto livello.",
    "Nei suoi molti viaggi crea una rete di contatti fidati e amici che lo supportano. Tra questi c'è anche il nonno di Erujhan, Larion, che infine lo contatterà chiedendogli di prendere con se il nipote Erujhan e farlo fuggire prima che sia reclutato.",
    "Dopo una forte resistenza iniziale, Miguel cede, e accoglie il giovane nella sua ciurma.",
  ],
  OLAR: [
    "Olar è un congegno molto complesso, un'armatura acherata che si muove e combatte da sola. È un'arma molto potente e versatile, eppure sembra avere una propria volontà e personalità.",
    "Le origini di questa misteriosa armatura non sono note. Miguel la incontrò per la prima volta quando ancora era un bambino, durante la fuga frenetica dal suo villaggio durante un'assalto di un clan rivale.",
    "Olar era il braccio destro del capoclan e rispondeva unicamente a lui, ed era uno dei pochi che sapeva guidare la Daitan, la nave volante. Quando il capoclan morì durante l'attacco, Olar passò in proprietà alla figlia del capoclan, la giovane Yori. Lei però non voleva avere altre avventure, e avendo lei stessa iniziato Miguel alla via del Cercatore, decise di inviare Olar con lui perchè lo aiutasse in tutto.",
    "Glie lo donò assieme alla nave volante, la Daitan. Da allora Olar viaggia sempre con Miguel ed è il suo più vecchio compagno d'avventura nonché un fedele amico. Olar, seppur incapace di comunicare verbalmente, è sempre stato capace di farsi capire e con il tempo parve sviluppare una certa simpatia per Miguel, nonostante non fosse umano.",
    "Miguel non ha mai chiesto a Olar di combattere o di usare forza letale, sebbene questo in principio era il suo ruolo. Invece, Miguel lo sfrutta come tutto fare e spesso lo usa per compiti di supporto come sfacchinare carichi o scavare buche per centinaia di metri.",
  ],
  SHARNEL: [
    "Brillante, intelligente, agguerrita. Sharnel Pitò è una donna di grande talento e di grande forza d'animo. Nata e vissuta a Umarsh, la ricca città dei nobili di Korianda, da subito ha dovuto confrontarsi con la cultura snob e arrivista di cui purtroppo il suo mondo era pregno. Dopo aver perso il marito e la figlia in un attentato fatto ai danni dell'organizzazione segreta di cui egli faceva parte, i cosidetti Veglianti, lei ha preso il posto del marito in tale organizzazione, spinta dal desiderio di trovare i colpevoli e vendicarsi.",
    "Con la sua abilità e con l'aiuto di alcuni amici, tra cui anche Miguel, riesce a scalare rapidamente le gerarchie dell'organizzazione, diventandone uno dei dodici capi. Con la sua abilità nello stringere alleanze riuscì a conquistare anche il posto di dirigente di una delle più grandi banche della sua città. Il suo potere politico divenne presto indiscusso.",
    "Oramai Sharnel è una donna molto potente, eppure sente ancora viva la ferita delle sue perdite passate. Odiando in modo viscerale la guerra e la corruzione, Sharnel decide di fondare vari orfanatrofi per accogliere i bambini rimasti orfani a causa della guerra, e di dare loro un'istruzione e un futuro.",
    "Successivamente, in uno degli orfanatrofi viene anche accolta una ragazzina con un dono particolare e un passato tragico, Erida Higgins. Sharnel si affeziona molto a lei, e inizia a volerle bene come una figlia. Purtroppo però, il passato della ragazza è tale da rendere impossibile la sua adozione.",
    "Così, cercando di salvare la vita della giovane, Sharnel si ingegna per costringere Miguel a prendere con se la ragazza. Egli si rifiuta costantemente, ma Sharnel non è donna che si perde d'animo.",
  ],
  ERIDA: [
    "Erida Higgins è una ragazza dolce ed emotiva, con un passato molto difficile. Si è trovata coinvolta nelle macchinazioni di un'organizzazione segreta finanziata dal governo, il progetto Esbjerg, che puntava a radunare bambini con particolari doti intellettuali per trasformarli in armi viventi.",
    "Rapita quando aveva appena sette anni, fu condotta nel centro di sperimentazione dove fu formato un gruppo di questi bambini prodigio. I capi del progetto, noti come Dottor F, Dottor J e Dottoressa M sono persone spietate che non hanno alcuna pietà per i loro soggetti di studio.",
    "Gli esperimenti nel tempo causano trasformazioni nella sua psiche, e la ragazza sviluppa una personalità multipla. La sua personalità principale, quella di Erida, è una ragazza dolce e gentile, ma la sua personalità secondaria, che lei stessa successivamente chiamerà Beren, è una personalità molto più cinica e spietata, oltre a essere smisuratamente acuta e intelligente.",
    "Anni dopo fu soccorsa con un'operazione condotta dai Veglianti, e fu l'unica che fu liberara insieme ad un altro ragazzo rapito, Naro Quins. I due finirono nelle mani dei Veglianti. Purtroppo Erida era troppo danneggiata per esser lasciata libera, e così venne accolta in uno degli orfanatrofi di Sharnel Pitò.",
    "Qui la ragazza ha cercato di riprendersi dall'esperienza traumatica, con l'aiuto di psicologi e medici. Quando circostanze complesse la portano a incontrare Erujhan, lei gli affida le sorti del suo destino.",
  ],
  DUSTEL: [
    "Dustel Sidorob è un giovane scapestrato con un talento per la meccanica e una forte sensibilità religiosa verso il culto dei sette. Per seguire i genitori che lavoravano viaggiando come Cercatori, è immigrato in Korianda con loro e la sorella. Partiti dai paesi dell'estremo sud, nel Dulenvir, la famiglia Sidorob si è stabilita nella cittadella dei Cercatori nel nord di Korianda, nota come l'Alveare.",
    "Nel loro piano originale, lui e la sorella sarebbero dovuti rimanere all'Alveare in attesa del ritorno dei genitori, mentre questi concludevano un lavoro a nord. Un tragico incidente causò la morte dei genitori, e i due ragazzi si trovarono soli al mondo.",
    "Il committente del lavoro dei genitori, sentendosi responsabile per la tragedia, aiutò Dustel a trovare un lavoro come apprendista in una delle officine meccaniche della città, dove però viene sfruttato e a stento riesce a pagarsi da vivere.",
    "Commosso dalla loro situazione e dalla tenacia di quei giovani, quell'uomo conduce Dustel in un ufficio di cercatori dove il proprietario gli deve un favore. L'ufficio in cui li conduce è chiamato Tesori per Tutti, e il proprietario, un certo Lotis, viene spinto ad assumere i ragazzi.",
    "La sorella di Dustel, Katerina, viene presto accolta presto nel gruppo di una donna Cercatore chiamata Sinikka, collega di Miguel, mentre il ragazzo non riesce a suscitare la simpatia di Miguel, il suo idolo, e quindi non può mai partire con lui. In seguito, si dedica a vincere con il gioco d'azzardo reperti di vario calibro, per poi rivenderli a Lotis.",
    "In questo modo riesce a cavarsela per qualche anno, ma la sua strategia presto inizia a mostrare i suoi limiti. Dustel si rende conto che ha bisogno di un vero lavoro, e decide di insistere con Miguel.",
  ],
  IRENE: [
    "Irene Ramnisk è la madre del protagonista, fu separata dal marito pochi anni prima dell'inizio della narrazione a causa della guerra.",
    "Spinta dalla sua situazione economica, è stata costretta a trasferirsi a Hedemora con i suoi due figli per vivere con i genitori, Larion e Ida. Qui ha cercato di rifarsi una vita, ma la pressione della guerra e la mancanza di notizie del marito l'hanno portata a un esaurimento nervoso.",
    "Lei è sempre stata una persona molto dolce e amorevole, ma anche molto fragile e sensibile. Dopo la scomparsa del marito e la partenza del figlio maggiore, Dorian, per la guerra, la donna è caduta in una profonda depressione.",
    "Si è sempre presa cura dei suoi figli con amore e dedizione, e ha cercato di proteggerli dagli orrori del mondo.",
    "Quando il figlio minore, Erujhan, è stato costretto a fuggire dalla guerra, lei ha cercato di opporsi, ma alla fine ha ceduto, sapendo che era l'unica possibilità di salvezza per il ragazzo.",
    "Al fine di far guadagnare tempo a Erujhan, la donna ha cercato di distrarre le autorità militari offrendo il suo servizio di infermiera per le truppe al fronte.",
    "Aveva saputo infatti che il marito era diventato un disertore in possesso di informazioni critiche, e che tutto il paese lo stava cercando.",
    "Lei sapeva, che in quanto potenziale esca per attirare il marito allo scoperto, l'esercito non avrebbe saputo resistere alla sua offerta e infatti la prese con se.",
    "Al suo ritorno ormai il figlio era già partito, e lei si è spostata assieme ai genitori a Terragia, anche per cercare di scoprire la verità sul marito e sul figlio maggiore.",
  ],
  HIUAN: [
    "Hiuan è conosciuto a Hedemora come monaco del culto cardico della cappella locale al paese. Quella però non è sempre stata la sua identità. Nacque in un paese lontano, in un tempo in cui l'ordine dei Collettori era ancora potente e in auge.",
    "In quel luogo, alcuni giovani venivano addestrati per diventare Collettori, e Hiuan era uno di loro. Dopo anni di addestramento, lui riuscì a passare la selezione finale e venne accolto nell'ordine.",
    "I suoi maestri si accorsero presto che era un prodigio nel combattimento e nel controllo del suo spirito, nonchè un abile stratega. Presto divenne uno dei migliori Collettori della sua generazione.",
    "La sua abilità nel manipolare l'antra lo rese un maestro del Dharma Talgalla, e con il tempo divenne un maestro di alto livello. La sua abilità nel combattimento e nel controllo del suo spirito lo rese un avversario temibile per chiunque.",
    "Raggiunto il grado di araldo, Hiuan entrò nella leggenda del suo ordine, inquanto era il più giovane mai arrivato a quel grado.",
    "Quando però il suo ordine venne attaccato e distrutto, Hiuan si ritrovò solo e senza un posto dove andare. La notte dell'attacco egli incontrò Larion, il nonno di Erujhan, con suo padre Ivan, al tempo anche lui membro dell'ordine con grado molto inferiore.",
    "Loro lo salvarono e lo condussero in un luogo sicuro. Da li, egli decise di abbandonare il suo paese e di viaggiare per il mondo, alla ricerca degli autori della cospirazione che aveva distrutto il suo ordine.",
    "Le sue ricerche purtroppo non trovarono seguito, così egli giunse a Hedemora, dove si stabilì e divenne un monaco del culto cardico. Quando a Larion gli chiese di ripagare il suo debito di vita addestrando il nipote nell'arte del Dharma Talgalla, egli conobbe la famiglia di Erujhan, e presto divenne un loro amico.",
  ],
  EDEN: [
    "Eden è un casinista con un gran cuore. Cresciuto in un paesino di periferia di Korianda, da subito ha dimostrato una forte propensione per la politica.",
    "Il suo impiego come gommista e meccanico non gli impedì di trovare il tempo di dedicarsi alla sua passione, la politica. Con il tempo, grazie alla sua abilità nel parlare e nel convincere la gente, divenne un personaggio molto rispettato e amato.",
    "Quando si accorse che il suo paese era in mano a una banda di corrotti, e che la politica centrale del governo non promuoveva persone del suo stampo, scelse ti tentare altre strade per fare la differenza.",
    "Iniziò guidando una banda di scapestrati suoi fedeli in operazioni di disturbo e sabotaggio ai danni delle multinazionali più corrotte, rimanendo però sempre nell'ombra.",
    "Alla fine riuscì ad attirare l'attenzione di un membro di un'organizzazione segreta, i Veglianti, che voleva sovvertire l'ordine costituito. Lo avvicinò tale Semel Humgran, membro dei Veglianti, che dopo un breve colloquio decise di accoglierlo nella sua organizzazione.",
    "Eden, con il tempo, divenne uno dei più abili agenti dei Veglianti, e con il tempo riuscì a scalare le gerarchie dell'organizzazione, diventando uno dei dodici capi.",
    "Da quella posizione riuscì a fare molto per il suo paese, e per il mondo intero. Con la sua abilità nel parlare e nel convincere la gente, riuscì a creare una rete di contatti e informazioni che fece la differenza anche nella guerra alle mafie locali.",
    "Eden diventerà presto amico di Sharnel Pitò, anche lei capo dei Veglianti, della quale rispetta più di tutto la forte integrità morale e la lealtà alla causa.",
  ],
  HOKU_HANTEKI: [
    "Hoku è un cangnolino simpatico e amichevole. Randagio fin dalla nascita, ha presto fatto amicizia con Selene, una ragazza che fin da piccola aveva avuto una particolare affinità con gli animali.",
    "Hoku è stato compagno di Selene in tante piccole avventure, e la sua presenza ha sempre portato un po' di allegria e di spensieratezza nella vita della ragazza.",
    "Con il tempo, Hoku è diventato l'amico più fidato della giovane, che non se ne separà mai.",
    "Hanteki è un'orfana della specie delle Phoenie, un popolo di creature floreali antropomorfe che vive nascosta dalla vista degli umani.",
    "La loro storica natura pericolosa le ha rese oggetto di una spietata caccia per il loro sterminio. Il suo gruppo è uno dei pochi che è sopravvissuto a questa epurazione.",
    "Nascoste in una caverna amena nella valle di Yurta, loro tempio e loro abitat, le Phoenie vivono in pace e armonia con la natura, e si dedicano alla cura e alla protezione delle creature del bosco.",
  ],
  LUCIAN_AND_MYA: [
    "Lucian è un ex cercatore ormai in pensione. Ha passato la sua vita a viaggiare per il mondo alla ricerca di tesori e informazioni, e ha visto cose che pochi altri possono dire di aver visto.",
    "La sua passione per il mondo antico è accompagnata da un forte senso di responsabilità verso la storia e la cultura del suo paese, e ha sempre cercato di preservare e proteggere i tesori del passato.",
    "Con il suo lavoro ha potuto stendere una rete di contatti che gli hanno permesso di ottenere informazioni preziose e di fare affari molto redditizi.",
    "Con il tempo, però, ha deciso di ritirarsi. Un suo caro amico e collega, Will Kell, morì durante una spedizione a cui parteciparono e lui ne uscì a stento.",
    "Sentendosi colpevole per aver incitato l'amico a partire con lui, decide di rigettare la professione di cercatore e di concentrarsi sul mercato dei reperti.",
    "La sua attività di mercante di reperti lo ha portato a conoscere Mya, una giovane e brillante storica che ha studiato all'estero e che ha deciso di tornare in patria per mettere a frutto le sue conoscenze.",
    "Mya lavora come gestore del suo negozio, Mondo Antico, situato all'interno del lussuoso albergo di Umarsh di proprietà di Sharnel Pitò.",
    "Egli ha ottenuto dalla padrona dell'albergo un accordo speciale che gli consente di vendere i suoi reperti all'interno del suo negozio senza quasi pagare affitto, e in cambio lui le fornisce informazioni preziose e reperti rari.",
  ],
  RAVEL: [
    "Nato nel 4098 della terza Era, Ravel è l'unico figlio di Vesnoy Shnaide ed ereditiero della fortuna Shnaide. Il padre è proprietario di varie compagnie con i diritti su alcuni dei più grandi giacimenti di petrolio al mondo. " +
      "Il giovane Shnaide ha da sempre avuto un'attitudine affaristica nei confronti della vita, e grazie all'impronta culturale della sua famiglia, ha presto formato un piccolo gruppo di figli di famiglie potenti per creare una cerchia " +
      "di affati con cui entrare in competizione perfino con il padre, cosa che Vesnoy apprezza e combatte con rigore allo stesso tempo. " +
      "Ravel è cresciuto in un ambiente molto competitivo e spietato, e ha imparato a destreggiarsi tra le insidie del mondo degli affari fin da piccolo. " +
      "La sua famiglia è anche collusa con alcune delle più grandi organizzazioni criminali del mondo, e Ravel è stato addestrato fin da piccolo a gestire i rapporti con queste organizzazioni. " +
      "All'età di ventuno anni ormai il padre inizia a lasciare che il figlio inizi a gestire alcuni aspetti delle loro compagnie. Una volta però Ravel commise un errore logistico e fece perdere un carico di petrolio, " +
      "il che sarebbe stato un disastro per la compagnia. Invece di notificare l'errore, tememndo che potesse emergere e giungere agli occhi del padre, Ravel si ingegnò per contattare in urgenza " +
      "una compagnia di trasporti diversa da quelle con cui di solito la famiglia faceva affari. In questo contesto conobbe Samuel Edkit. Rispetto a Ravel, Samuel aveva origini meno prestigiose, ma Ravel apprezzò come Samuel riuscì a salvarlo " +
      "e come si dimostrò compiacente nell'agevolarlo in modo valido e affidabile. Perciò, nonostante Samuel non fosse ricco di famiglia, lo accolse nella sua cerchia. Samuel si rivelò un vero genio e con il suo aiuto, Ravel potè creare un piccolo " +
      "impero di affari che gli permise di guadagnare una fortuna in breve tempo. Oggi il gruppo di Ravel conta sugli eredi di alcuni dei più grandi nomi del mondo degli affari, e Ravel è considerato uno dei più grandi imprenditori del suo tempo. ",
  ],
  NARO: [
    "Naro Quins nasce nella città di Debecia in Korianda, in un contesto amorevole e sereno. Come Erida, egli è stato rapito dai servizi segreti governativi quando ancora molto piccolo, per essere trasformato in un super genio di tattica bellica all'interno del progetto segreto Esbjerg.",
    'Rapito quando aveva solo dieci anni, fu condotto nel centro di sperimentazione dove fu formato un gruppo di questi bambini prodigio. I capi del progetto, noti come Dottor F, Dottor J e Dottoressa M gli affibbiarono il nome in codice "Saprar" e lo inserirono nel progetto senza esitazione.',
    "Due anni dopo la sua cattura, Naro fu liberato da un'operazione condotta dai Veglianti, e fu portato in un orfanotrofio di Sharnel Pitò assieme a Erida.",
    "Ci fu un tentativo di riconsegnarlo alla sua famiglia, ma i genitori furono fulminati prima di poterli mettere in salvo. I veglianti intuirono che fosse opera di agenti governativi che stavano ripulendo le tracce di persone connesse ai ragazzini rapiti, proprio come accadde per i genitori di Erida.",
    "Così, sia Lui che Erida tornarono all'orfanatrofio. Qui la ragazza ha cercato di riprendersi dall'esperienza traumatica, con l'aiuto di psicologi e medici. Invece Naro sembrava non aver subito danni particolari e dopo pochi mesi chiese di collaborare per con i Veglianti per trovare gli autori dei rapimenti.",
    "I veglianti lo misero alla prova con piccoli lavori di intelligence, e lui riuscì a risolverli brillantemente. Da li, fu introdotto al team di spionaggio dei veglianti, quello gestito dal dottor Sabré, morto anche lui per il suo coinvolgimento nel sabotaggio del progetto Esbjerg.",
    "Vista la sede vacante e il talento di Naro, unito alla sua determinazione di fare a pezzi ciò che rimaneva del progetto, i Veglianti lo promossero a capo del team, e divenne membro in prova del consiglio dei dodici capi.",
    "Da allora alterna il lavoro per l'organizzazione alla sua vendetta personale, in attesa del momento di colpire i suoi rapitori.",
  ],
  LOTIS_AND_PASCALE: [
    "Lotis Umber è un uomo dal passato carico di ferite. Cresciuto con due fratelli nell'Olongea, la congrega dei paesi del sud, ha dovuto affrontare la morte dei genitori quando aveva solo quindici anni.",
    "Dopo la morte dei genitori, lui e i suoi fratelli furono costretti a trasferirsi a Korianda, nella città di Etei, vicino al mare, dove Lotis iniziò a lavorare come apprendista in una bottega di fabbri.",
    "I fratelli invece, Irvan e Khalim, iniziarono a lavorare come mercenari per una delle tante compagnie di Korianda. Nel tempo divennero abili conoscitori del mondo e delle sue leggi, e iniziarono a fare affari con i Cercatori.",
    "Presto scoprirono di avere un talento per gli affari, e decisero di aprire un negozio di reperti antichi, Tesori per Tutti. Lo fecero nella sede degli uffici di Cercatori che si trovava nell'Alveare, che era il nome della cittadella centrale di tutti i Cercatori in Korianda.",
    "Il negozio non trovò molta fortuna, i due fratelli faticarono a trovare cercatori validi che accettassero di lavorare per loro ai bassi compensi che potevano permettersi.",
    "Lotis nel frattempo si sposò con una donna, Lila. La loro vita sembrava procedere tranquilla, fino a che un giorno non giunse la notizia della morte dei fratelli.",
    "Entrambi erano stati assassinati da un gruppo di ladri che si era introdotto nel loro negozio sperando di trovare oggetti di valore.",
    "Questo distrusse Lotis che si recò, come loro unico erede, all'Alveare per chiudere il negozio e vendere i beni.",
    "Quando arrivò li scoprì che il negozio aveva solo debiti che si sarebbe dovuto accollare lui. La moglie, rifiutando di andare in miseria per quella storia, chiese il divorzio e lo lasciò.",
    "Con Lotis rimase solo una cercatrice che si era affiliata all'ufficio quando ancora i fratelli erano in vita. Sinikka, collega di Miguel, aiutò Lotis a orientarsi con la burocrazia e lavorò con lui per rimettere in sesto l'ufficio.",
    "Le cose iniziarono a migliorare leggermente quando assunsero un'altro cercatore, Raw, che si rivelò un vero talento. Con il tempo, l'ufficio riprese a funzionare e Lotis riuscì a ripagare i debiti.",
    "Tempo dopo Lotis incontrò anche Miguel, al quale serviva disperatamente un'affiliazione con un ufficio per poter praticare la sua professione. Lotis, che aveva bisogno di personale, lo assunse.",
    "Con il tempo, Miguel divenne uno dei migliori cercatori dell'ufficio, e Lotis riuscì a far crescere l'ufficio ulteriormente.",
    "Assunse una stagista gentile e intelligente, Pascale, che da mesi non riusciva a essere assunta in nessun altro luogo. La donna liberò Lotis di molti impegni e questo gli permise di poter ottenere clienti sempre migliori.",
    "L'ultimo ad essere assunto in quell'ufficio fu il giovane Dustel, che Lotis accolse dopo varie resistenze.",
  ],
  BEREN: [
    "Beren è spesso superficialmente considerata come la personalità secondaria e dormiente di Erida Higgins, ma in realtà è molto di più.",
    "Inizialmente è emersa in modo traumatico nel periodo in cui Erida, ormai salvata dal progetto Esbjerg, aveva smesso di essere sottoposta agli esperimenti del dottor F.",
    "La droga con cui stavano plasmando la mente di Erida teneva Beren dormiente, in gestazione, e quando la ragazza smise di assumerla, la personalità secondaria si risvegliò.",
    "Nel piano originale del progetto Esbjerg, infatti, Beren avrebbe dovuto sostiuire integralmente Erida, trasformandola in una macchina da guerra.",
    "Essendo l'esperimento stato interrotto nel suo corso, le due menti, quella di Erida e quella di Beren, sono state obbligate a coesistere, con un marcato dominio da parte della psiche di Erida.",
    "Beren non può uscire, ne comandare il corpo, se Erida non lo consente. L'unico caso in cui Beren è in grado di uscire autonomamente è quando Erida è in uno stato di panico.",
    "Durante le prime manifestazioni dell'altra se, Erida credeva solo di sognare, ma quando Beren iniziò a prendere il controllo del suo corpo, la ragazza si rese conto che qualcosa non andava.",
    "Erida infatti i primi tempi all'orfanatrofio Hendikva era assalita da incubi ricorrenti e questi le causavano attacchi di panico. In quei momenti, Beren prendeva il controllo del corpo e lo usava per gironzolare nell'orfanatrofio di notte.",
    "Erida tentò anche di dialogare con l'altra se usando un sistema di bigliettini di carta. Con sua sorpresa, l'altra se le rispose.",
    "Pian piano Erida imparò come sbloccare Beren e come farla uscire, e con il tempo le due iniziarono a dialogare sempre di più.",
    "Erida comprese che Beren era una personalità molto più complessa di quanto avesse mai immaginato, e decise di darle il nome Beren, in onore a un personaggio di un libro che la ragazza adorava che aveva una protagonista in una condizione simile.",
    "Quando i veglianti scoprirono della cosa, tentarono di rimuovere Beren dalla sua mente, ma i tentativi non fecero che rendere evidente la sua presenza simbiotica nella mente della ragazza.",
    "Beren è una personalità molto cinica e spietata, ma anche molto intelligente e acuta.",
    "Beren si rivelò molto abile nel manipolare le persone e le situazioni, e per un periodo riuscì persino a convincere i veglianti ad usarla come pianificatrice.",
    "Alcuni accolsero la sua esistenza come un dono, altri come un pericolo. Alla fine, vinse la seconda opinione, e Beren fu allontanata con Erida, che rimase prigioniera nell'orfanatrofio fino a quanto incontrò Erujhan.",
  ],
  KAORI_ASAMI: [
    "Kaori è la sorella minore di Miguel. Quando ancora era una bambina, il suo villaggio nel paese di Migulma è stato attaccato all'improvviso da un clan rivale.",
    "La battaglia fu improvvisa e sanguinosa, e non fu possibile difendersi in alcun modo. Il capoclan fu ucciso nel tentativo di aiutare la figlia, la piccola Yori, a fuggire verso la sua nave volante.",
    "Il padre di Miguel, amico del capoclan, si fece avanti e condusse Yori assieme ai suoi figli verso la nave volante.",
    "Sulla nave li aspettavano Asami, che era la governante di Yori, e Olar, l'armatura magica a servizio del capoclan. I due avevano ricevuto l'ordine di portare i bambini in salvo.",
    "Purtroppo il anche il padre di Miguel fu ucciso in quel momento, e Kaori rimase sola in mezzo alla battaglia.",
    "Asami, in un impeto di coraggio, saltò giu dalla nave e afferrò Kaori portandola via. Olar fece partire la nave e così Miguel perse di vista la sorella.",
    "Asami fuggì a cavallo e continuò funo a varcare il confine con Korianda. Una volta li, ha vissuto in incognito con la piccola Kaori, che ha cresciuto come fosse sua figlia. La donna faticò a trovare un luogo sicuro che potesse chiamare casa, questo perchè i nemici del loro clan erano ancora in cerca di eventuali superstiti.",
    "Asami, dopo molte difficoltà, trovò la grazia di un gruppo di inservienti di una prestigiosa villa. Questi le offrirono un lavoro come cuoca e governante, e lei accettò.",
    "La villa in cui si recarono era del barone Haakon Riftikye, un uomo molto ricco e potente che controllava una grossa fetta dell'industria di produzione dell'energia tramite gas naturale.",
    "Con il tempo, le vicende della villa vennero a galla, perciò Kaori e Asami dovettero imparare a confrontarsi con esse.",
  ],
  SELENE: [
    "Selene è nata nel villaggio della nebbia, così chiamato per la nebbia sempre presente nella valle in cui è situato. Il suo villaggio è da sempre isolato e ha contatti minimi e occasionali con il resto del mondo.",
    "Questo ha fatto si che quasi tutti i suoi abitanti non siano mai usciti dai suoi confini, e il villaggio è rimasto arretrato riguardo la tecnologia e la cultura.",
    "Nonostante ciò, il villaggio era molto prospero e i suoi abitanti erano sicuri. La nebbia, infatti, li proteggeva da eventuali attacchi esterni.",
    "Selene è cresciuta in questo ambiente, e fin da piccola ha dimostrato una particolare affinità con gli animali. Ha sempre avuto un talento naturale nel comunicare con loro, e spesso si è trovata a soccorrere animali feriti o abbandonati.",
    "La sua vita cambio quando un giorno alle loro porte si presentò un uomo ferito, un vecchio, che si rivelò essere una persona molto importante.",
    "Egli condusse nel villaggio un tesoro prezioso e per proteggerlo, produsse una barriera che occultava ulteriormente il villaggio rendendolo invisibile.",
    "Il vecchio passò degli anni nascosto li e insegnò ad alcune donne del luogo l'arte della cura e della medicina. Selene fu una delle sue allieve più brillanti.",
    "Egli applicò a tutte le alieve dei sigilli che permettevano di controllare l'antra in un modo unico.",
    "In seguito egli fondò l'ordine delle sacerdotesse della mezza luna, in cui entrò anche la madre di Selene. Selene putroppo non potè entrare perchè il capo di quell'ordine, la divina Elionor, temeva il talento della ragazza e non voleva rischiare di essere soppiantanta.",
    "Da allora Selene si è dedicata allo studio delle erbe curative e degli unguenti.",
  ],
  CAPITANO_OBERON: [
    "Il capitano Oberon nasce nel Dulenvir nel 3961 della terza era, nella città di Ekhen, la città del re. Fin da piccolo è in contatto con le alte sfere grazie a sua madre Khalisa, che era una cortigiana del re, ma anche una donna molto ambiziosa e arrivista. Il marito, divenuto erore di guerra in una battaglia anni prima, le aveva fatto da tramite con il re e da allora lei aveva continuato a cercare di compiacerlo in tutto.",
    "Lei però non aveva a cuore il re o il suo regno, voleva solo il potere e la ricchezza che ne derivava. La loro famiglia aveva per secoli lavorato per avvicinarsi sempre di più alla nobiltà, e spesso nel farlo si era sporcata le mani aiutando i nobili con questioni che i reali speravano di tener segrete.",
    "Per questo motivo, seppur non essendo nobili, la loro famiglia godeva della benevolenza del re. Oberon, fin da quando aveva otto anni, fu addestrato per diventare un cavaliere del re. Inizialmente non era molto convinto, e non riusciva nemmeno a spiccare particolarmente rispetto agli altri apprendisti, che lo schernivano per non essere un vero nobile di famiglia.",
    "La madre allora introdusse il figlio ad alcuni dei segreti più intimi della loro famiglia, l'arma che aveva permesso loro di salire di rango. Nella loro stirpe erano presenti alcuni grandi maestri del Dharma Talgalla, e uno di loro ancora viveva. Lo zio, Artemis, era stato una guardia del re tutta la vita e il suo successo era derivato principalmente dal vantaggio costituito dal conoscere la perduta arte del Dharma Talgalla.",
    "Avrebbero voluto attendere di più prima di consegnare il ragazzo allo zio, che avrebbe applicato durissimi addestramenti, ma la madre iniziava a temere che il figlio perdesse la grazia del re, e quindi lei acconsentì perchè il giovane Oberon iniziasse subito, all'età di dieci anni.",
    "Nell'arco di sei anni, Oberon divenne più forte anche fisicamente per via dei duri addestramenti dello zio. A sedici anni surclassava i suoi compagni apprendisti al punto che potè entrare direttamente nella guardia reale, la sezione dell'esercito che rispondeva direttamente al re. Un grande onore, e agli occhi della madre un passo nella giusta direzione.",
    "All'età di appena diciotto anni, nel 3979, partecipò come truppa èlite nella battaglia di Fortnostus, nella quale svolse un ruolo cruciale nel raggiungimento della vittoria.",
    "Il loro caposquadra, morto in battaglia, aveva lasciato il loro battaglione privo di ordini. Oberon assunse il comando dei suoi compagni grazie solo al loro rispetto di lui, e li guidò fin nella fortezza nemica.",
    "Uccise lui personalmente il capo dei rivoltosi e portò la testa al re. Seguì l'inevitabile promozione a caposquadra. Due anni dopo seguì la sanguinosa battaglia di Mikornia, nel 3981, poi ancora quella di Alteniva nel 3984.",
    "Oberon accumulava vittorie su vittorie. Il re, ammirato per l'ambizione e la forza del giovane, lo promosse Capitano del re a soli venticinque anni, nel 3986.",
    "La madre, nell'ombra, gustava avidamente ogni vittoria del figlio. A quel punto lo zio gli disse che non poteva insegnargli più nulla, ma Oberon desiderava diventare ancora più forte.",
    "Così, lo zio gli parlò di un monaco, un grande maestro del Dharma Talgalla, che molti anni prima fu suo maestro. Spinto dalle pressioni della madre, Oberon partì alla ricerca del monaco nel 3987.",
    "Incontrò infine il monaco Hutus nella valle di Triba, nel 3989, che dopo un breve scontro lo accettò come alievo in cambio di una frazione della riccheza che stava accumulando al servizio del re.",
    "Solo un anno dopo Oberon già padroneggiava i sigilli d'antra. Il monaco Hutus era impressionato dalla dimestichezza di Oberon con l'antra.",
    "Nel 3993 dovette interrompere gli addestramenti perchè richiamato dal re. Alcune insurrezioni stavano destabilizzando il potere del re, così lui tornò e guidò la campagna.",
    "Guerra di Isonabi nel 3994, e guerra di Okhulma nel 3996. Quest'ultima, fu conclusa con una trucidazione di massa di soldati e popolo nemici.",
    "La sete di sangue di Oberon a quel punto era senza limiti, ma a lui non importò dare spiegazioni e lo stesso anno tornò dal suo maestro.",
    "Nel 4004 sbloccò la tecnica più segreta del maestro, l'antra vampirico. Una tecnica curativa che permetteva di utilizzare il proprio antra per assorbire vita da un'altro individio, curando se stessi e prolungando la propria esistenza.",
    "Oberon rimase con il monaco fino al 4006 per perfezionare la sua tecnica. Nel 4007 il re, spaventato del potere e del prestigio aquisito da Oberon, decise di tradirlo.",
    "Fece rintracciare lui e il suo maestro. In una trappola organizzata da molti assassini reali, il re tentò di eliminare entrambi. Il monaco Hutus morì nell'attacco e Oberon si salvò per puro miracolo, riuscendo a fuggire.",
    "Per alcuni anni vagò come rinnegato nelle terre del suo sovrano, nascondendosi e spostandosi continuamente. Nel 4010 decide di unirsi con alcuni dei ribelli che prima combatteva.",
    "I ribelli più carichi di odio per il potere del re, i Dul'Zhar. Nel 4016 Oberon condusse una campagna dei Dul'Zhar contro il re, e tre anni dopo, riuscì ad arrivare alle porte della sua fortezza.",
    "Lo uccise con le sue stesse mani, assorbendo da lui ogni goccia di vita che gli restava usando l'antra vampirico, quindi, se ne andò.",
    "Il dulenvir venne terribilmente destabilizzato, insurrezioni si sparsero a macchia d'olio e il principe successore del re a stento riuscì a frenarle.",
    "Intanto Oberon, ormai legato alla setta dei Dul'Zhar, nemica giurata anche dei Collettori, accetto di lavorare per loro contro di essi.",
    "Nel 4075, i Dul'Zhar portarono avanti un'attacco lampo ai danni dei Collettori, colpendoli in tutte le loro basi. A lui fu assegnato uno dei tomi di Khalandi, che erano la reliquia a cui i Dul'Zhar puntavano maggiormente.",
    "In quel contesto, Oberon si battè con Hiuan e perse il libro. Da allora, attende il momento per reincontrare l'araldo dei collettori e pareggiare i conti.",
  ],
  COMANDANTE_LANE: [
    "Lane viene da una famiglia di umili origini, cresciuto in un piccolo villaggio di agricoltori nel nord di Korianda, da sempre aspirava a una vita più gloriosa di quella. Fin da piccolo aveva dovuto confrontarsi con la situazione economica della famiglia, ai limiti della povertà.",
    "Il padre, un uomo molto severo e autoritario, lo aveva costretto a lavorare duramente fin da piccolo, e lui aveva imparato a cavarsela da solo. La madre, invece, era una donna molto dolce e amorevole, che cercava di proteggerlo dal padre e di dargli un po' di affetto.",
    "Lane aveva sempre sognato di diventare un soldato, e fin da piccolo aveva iniziato ad addestrarsi duramente per raggiungere il suo obiettivo. Il padre, però, non era d'accordo con la sua scelta, e cercava di dissuaderlo in tutti i modi.",
    "Quando Lane compì diciotto anni, decise di partire per la capitale per arruolarsi nell'esercito. Il padre, furioso, cercò di fermarlo, ma lui riuscì a scappare e a raggiungere la città.",
    "Una volta lì, si presentò al quartier generale dell'esercito e chiese di essere arruolato. Il comandante, colpito dalla sua determinazione, lo accettò e lo mandò a fare il corso di addestramento.",
    "Purtroppo, seguirono le indagini di ruotine ed emerse che il padre di Lane aveva un passato con un gruppo di ribelli, e che era stato coinvolto in un attentato contro il governo. Al che Lane fu costretto a lasciare la squadra.",
    "Lane, deluso e amareggiato, decise di arruolarsi nella guardia cittadina, un gruppo di mercenari gerarchizzati in modo strutturato, dove prestò servizio per diversi anni. Con il tempo, riuscì a scalare le gerarchie e a diventare comandante di una squadra.",
    "Seguirono una serie di missioni pericolose e rischiose, in cui Lane dimostrò il suo coraggio e la sua abilità nel combattimento. Con il tempo, riuscì a guadagnarsi la fiducia del suo superiore, il capitano d'armata della guardia, e a diventare uno dei suoi più fidati collaboratori.",
    "La sua vita continuò così per diversi anni, finché un giorno un ricco e potente magnate dell'arte antica con ottimi legami politici, cerco i servizi della guardia cittadina.",
    "Il magnate, tale Valiant Stirr, doveva recuperare velocemente un raro reperto che gli era stato appena rubato, e non si fidava a coinvolgere le autorità del paese, temendone non solo la lentezza burocratica, ma anche l'inefficienza tecnica.",
    "Lanel venne convocato dal suo superiore e li conobbe il signor Stirr. Questi gli offrì una somma di denaro molto alta per recuperare il reperto, e Lane accettò.",
    "Parti con duecento uomini verso la valle di Yurta, il luogo dove l'areo del ladro del reperto si era schiantato. Li, iniziarono a eseguire una serie di incendi controllati per disboscare rapidamente la valle e stanare il ladro.",
    "Le cose però non andranno come lui aveva pianificato.",
  ],
  DOTTOR_F: [
    "Il dottor F fin dalla giovinezza è stato a contatto con il mondo della scienza. Spinto dal padre, medico e ricercatore di prestigio, intraprese la carriera universitaria in medicina nella capitale di Korianda, Sordan.",
    "Seppur abile negli studi, non ebbe mai alcun particolare interesse per la medicina, e si appassionò invece alle lezioni di un brillante scenziato che teneva un corso sulle cellule staminali. Il professor Bogal khium, al tempo un lumirare di quel campo, fece appassionare il dottor F di biologia e alla genetica.",
    "Dopo la laurea, egli si trasferì nella città di Aniule, dove il professor Bogal lo aveva indirizzato a un suo collega, avendo riconosciuto il talento di lui. Grazie a quell'aggancio, il dottor F iniziò a lavorare come ricercatore presso il laboratorio di biologia molecolare della WestPasr, dove conseguì anche due dottorati.",
    "Alla WestPasr incontrò una giovane dottoranda in biochimica applicata all'ingegneria evolutiva, la dottoressa M, con la quale subito si trovò in forte sintonia per i comuni principi e le comuni ambizioni.",
    "I due iniziarono a lavorare insieme a un progetto di ricerca che dopo solo due anni li condusse a creare un siero che permetteva di curare alcune forme di tumori, e fu un successo.",
    "Il loro prestigio crebbe enormemente e la WestPasr li promosse a capi del laboratorio. Il dottor F e la dottoressa M iniziarono a lavorare a un nuovo progetto, che avrebbe dovuto rivoluzionare il mondo della medicina.",
    "Un siero che permetteva di rigenerare i tessuti danneggiati, e che avrebbe potuto salvare milioni di vite. Il progetto, chiamato Serafina, fu finanziato dalla WestPasr e da altre grandi multinazionali, e i due iniziarono a lavorare con grande entusiasmo.",
    "Il progetto, però, si rivelò molto più complesso di quanto avessero previsto, e i due scienziati si trovarono a dover affrontare una serie di problemi tecnici e etici che minavano la loro ricerca.",
    "Il dottor F, spinto dalla sua ambizione e dalla sua sete di successo, decise di proseguire nonostante tutto, e iniziò a sperimentare il siero su cavie umane.",
    "Questo provocò una serie di reazioni avverse nei soggetti, che iniziarono a manifestare gravi problemi di salute. Il dottor F, però, non si fermò, e continuò a sperimentare, convinto che avrebbe trovato una soluzione.",
    "Infine, il progetto fu sospeso dalla WestPasr, che decise di chiudere il laboratorio e di licenziare i due scienziati per i molti protocolli che avevano infranto. Il dottor F, però, non si arrese, e decise di continuare la ricerca da solo.",
    "La svolta avvenne quando l'esercito lo coinvolse per riaprire la sua ricerca sotto la loro giurisdizione. Il dottor F accettò, e iniziò a lavorare per loro, sperimentando il siero su soldati feriti in battaglia.",
    "Infine il siero divenne stabile e il suo successo fu eclatante. L'unico problema era che i materiali e i tempi richiesti per produrre il siero erano onerosi, così la richiesta divenne di renderlo più economico.",
    "Il dottor F disse che non avrebbe potuto completare quella ricerca da solo, in quanto il merito era condiviso con la dottoressa M, che nel frattempo si era spostata in un'altro centro di ricerca.",
    "I due furono riuniti dall'esercito e dovettero dare una descrizione di cosa gli sarebbe servito per completare il progetto. Il dottor F chiese un laboratorio e un team di ricerca, e la dottoressa M chiese di poter lavorare con lui.",
    "Il problema erano le tecnologie coinvolte. Per lavorare in tempi ragionevoli, avevano bisogno di strumentazioni all'avanguardia. L'esercito, però, non poteva permettersi di finanziare un progetto così costoso.",
    "Per loro fortuna, un generale aveva seguito tutta la vicenda, essendo interessato al potenziale di quella scoperta, specialmente nell'ottica di poterla usare per creare soldati invincibili.",
    "Coinvolse così il dottor F e la dottoressa M in un progetto segreto che non sarebbe comparso negli atti ufficiali. Avrebbero spostato le loro attività sotto la giurisdizione della CRAPBA, un'azienda militare che si occupava di ogni sorta di progetto segreto di stampo bellico.",
    "In quel contesto, gli venne fornito un laboratorio segreto per lavorare al progetto Serafina, e la dottoressa M era con lui. Parallelamente a questo, visto il loro talento, il generale chiese che si spostassero anche a gestire una moltitudine di altri progetti paralleli.",
    "Vennero quindi messi in contatto con il dottor J, che era il capo del consiglio di tutte le aree di ricerca e sviluppo della CRAPBA.",
    "Insieme formarono un tavolo di lavoro che si occupava di coordinare tutte le attività di ricerca e sviluppo dell'azienda, e il dottor F e la dottoressa M presto divennero i capi di quel tavolo al pari del dottor J.",
    "Da allora, lavorano nell'ombra con il solo scopo di sviscerare il potenziale della ricerca scientifica, e segretamente i tre condivisero l'ambizione di usare la loro ricerca al fine di individuare una soluzione per la mortalità dell'uomo.",
  ],
  UMBRI: [
    "Umbri, nato nell'anno 2821 della seconda era, fu l'imperatore di un popolo di nomadi che vivevano nelle terre desolate del nord di Fedora, i Sabarani. Il suo popolo era noto per la sua abilità nel combattimento e per la sua resistenza alle avversità del clima.",
    "Sebbene avesse assoggettato tutte le terre del nord, egli nutriva una forte invidia per i più prosperi popoli del centro-sud, che vivevano in città ricche e fiorenti. Umbri si convinse che desiderava per il suo popolo una vita migliore, e per questo motivo decise di intraprendere una serie di campagne militari per conquistare le terre del centro-sud.",
    "Sapeva però di essere in inferiorità numerica rispetto ai popoli del centro-sud, perciò iniziò a cercare risposte nei chiaroveggenti e nelle arti oscure. Presto entrò in contatto con i culti oscuri fondati dai seguaci di Urhari, il dio della distruzione.",
    "Per anni il male di Urhari lusingò la sua mente, e presto quello che rimaneva del suo pensiero per il bene per il suo popolo fu sostituito da un malato desiderio di puro dominio. Così, lui iniziò a compiere atti sempre più crudeli e sanguinari sotto la guida di alcune streghe per ottenere il potere di cui aveva bisogno.",
    "Queste streghe del culto oscuro gli promisero l'accesso ad alcune arti proibite che avevano lo scopo di fortificarlo. Trasformarono sua insaputa Umbri in un Lich, un non morto dall'enorme potere ma vincolato a un giuramento. Come filatterio, l'oggetto a cui viene legata l'anima di un Lich, le streghe scelsero un antico amuleto che Umbri aveva ereditato dal padre.",
    "Come giuramento, le streghe chiesero l'eterna fedeltà ai signori della tenebra. Non capendo di cosa si parlasse, Umbri accettò per impazienza di diventare invincibile.",
    "Giunse così il momento in cui si sentì pronto ad affrontare apertamente i popoli del centro-sud. I poteri oscuri che aveva acquisito lo resero un nemico potente, e inizialmente i popoli attaccati non riuscirono a fermarlo.",
    "Le sue campagne furono molto cruente e sanguinose, e portarono alla distruzione di molte città e villaggi. Umbri, però, non si fermò, e continuò a combattere per il suo dominio.",
    "Eventualmente, i popoli del centro-sud si unirono contro di lui e lo sconfissero in una serie di battaglie decisive. Umbri fu costretto a ritirarsi nelle terre del nord, dove dovette rifugiarsi con quello che rimaneva della sua gente.",
    "Il suo popolo, riconoscendo che ormai l'imperatore era impazzito, decise di abbandonarlo. Egli vagò da solo, per anni, nelle terre desolate del nord, tenuto in vita solo dai malefici sortilegi compiuti tramite il culto oscuro.",
    "Il suo odio per i suoi avversari non accennò a diminuire, ma ormai non aveva più un esercito, e con la morte delle sue streghe perse la capacità di rigenerare nuovi poteri oscuri. Perciò, si ritirò in una caverna ad attendere la fine, meditando incessantemente sulla sua sconfitta.",
    "Egli rimase in quella grotta per quasi due secoli, incapace di vivere, incapace di morire. Fu verso l'anno 2995 della seconda era che Umbri fu individuato da alcuni servi di un'altro dio oscuro, Mamellon il corruttore.",
    "Essi da tempo lo cercavano e infine riuscirono a individuarlo quando egli, stanco della sua condizione, tentò di distruggere se stesso con un rituale oscuro che però non ebbe effetto.",
    "Mamellon lo trovò e si recò da lui di persona per offrire un patto. In cambio della sua servitù, gli avrebbe fornito il suo esercito di morti viventi e gli avrebbe dato la possibilità di vendicarsi dei suoi nemici.",
    "Umbri, che per il vincolo del suo giuramento era tenuto a servirlo, accettò. Mamellon usò Umbri come sua arma più devastante e lo rese generale delle sue armate. Conferì ad Umbri nuovi poteri oscuri che lo trasformarono in un titano mosso da un solo desiderio.",
    "Compiere la volontà del suo nuovo padrone. La distruzione che seguì fece piombare il mondo in una tenebra che pareva ineluttabile.",
    "Questo durò fino al 3028, anno in cui Umbri dovette battersi con il divino Khalindar, campione dei sette dei, che lo sconfisse e lo sigillò per sempre.",
  ],
  RAZENNA: [
    "Razenna è nata nel 3853 della terza era. Al tempo era solo una ragazza come tante altre. Cresciuta in un villaggio di allevatori e contadini dell'Olongea, lei non aveva mai avuto grandi ambizioni nella vita.",
    "Viveva da sola con la madre e la sorella. Il padre, cacciatore, era morto quando lei era ancora piccola e la madre aveva dovuto fare i lavori di entrambi per mantenerle.",
    "Il villaggio in cui viveva era isolato, alto sulle colline, e nascondeva un terribile segreto. In quel villaggio infatti erano giunte a vivere alcune persone legate ad uno dei culti oscuri di Urhari, il dio della distruzione.",
    "In particolare, in quel villaggio, i membri della setta veneravano Livurn, un demone minore di Urhari, che aveva il potere di scambiare anni della vita tra le persone.",
    "Il rituale richiedeva vittime umane, ed aumentava di potere se la vittima era giovane, richiedeva però che la vittima fosse pura di cuore. Lei che era una ragazza molto bella e giovane, fu scelta dai membri della setta come sacrificio.",
    "I sei membri della setta infatti, ogni dieci anni sacrificavano una vittima per spartirsi tra loro gli anni di vita che sarebbero rimasti alla persona scelta.",
    "Quando con il passare del tempo diventava evidente che i membri della setta non invecchiavano, questi cambiavano villaggio e ricominciavano da un'altra parte.",
    "Avevano già compiuto due sacrifici in quel luogo, negli ultimi vent'anni, e presto giunse il momento di ripetere il rituale.",
    "L'ultimo sacrificio avvenuto aveva coinvolto la sorella di Razenna, Miluna, che fu scelta per il rituale. Dalla sua scomparsa, il dolore non lasciò mai il cuore di Razenna.",
    "Un giorno, mentre passeggiava nei boschi, Razenna fu rapita dai membri della setta e portata nel tempio di Livurn.",
    "Il tempio era situato nel seminterrato della casa di un membro della setta che viveva ai margini del villaggio, vicino la foresta. Lì, Razenna fu legata a un altare di pietra e preparata per il sacrificio a Livurn.",
    "Il rituale fu condotto tra le urla della ragazza, mentre i membri della setta recitavano le antiche formule e invocavano il demone.",
    "Venne cosparsa degli oli del rituale e costretta a bere la pozione che preparava il corpo a essere consumato dal demone.",
    "Quando il demone apparve, Razenna si sentì avvolta da un'oscurità profonda, e vide il demone avvicinarsi a lei con un sorriso malvagio.",
    "Quando fu il momento di morire, Razenna sputò in faccia al demone e lo insultò. Il demone, colpito dalla sua audacia, rimase ad osservarla.",
    "Lei lo fissò con odio e disprezzo, e gli disse che non avrebbe mai accettato di essere sacrificata a lui.",
    "Il demone Livurn riconobbe che la ragazza aveva una forza spirituale fuori dal comune, e vide in lei un'opportunità.",
    "Si accostò al suo orecchio e le disse che lui aveva con se l'anima della sorella.",
    "Al che Razenna capì e urlò con odio e disperazione. Il demone le disse che se avesse accettato di diventare sua servitrice, avrebbe potuto riavere la sorella.",
    "Razenna, che amava al mondo solo la sorella, accettò. Aprì il suo cuore e Livurn entrò in lei, rendendola sposa nelle tenebre, e facendo di lei la sua strega.",
    "I sigilli del patto di Livurn le coprirono il corpo e la resero sua. Lei prese ad ardere di fuoco in una trasformazione violenta e dolorosa. Le crebbero le corna di Livurn sul capo e il suo cuore divenne nero.",
    "A quel punto, Livurn in lei spaccò le catene con cui Razenna era legata, poi da lei si sparse come una nuvola verde sui i membri della setta, sollevandoli dal suolo e divorandoli fino alle ossa.",
    "Nessuno di loro si salvò. Livurn, soddisfatto, si ritirò in lei e la lasciò sola, con la promessa che avrebbe potuto riavere la sorella.",
    "Razenna, che ora era dannata, non poteva tornare al villaggio, e così si diresse verso la foresta, dove avrebbe iniziato la sua nuova vita come strega di Livurn.",
    "Viaggiò per molti chilometri, spostandosi lontano da tutti. Dopo tanti giorni di cammino, giunse nella città perduta di Sekurmina, nascosta in una foresta abitata dai Fenili, il popolo barbaro che infestava Fedora. Si stabilì lì e iniziò a praticare le arti oscure sotto la guida di Livurn.",
    "Con i fenili lei aveva rapporti occasionali per l'acquisto di ingredienti per le sue pozioni, e in cambio li aiutava con le sue arti oscure.",
  ],
  AMMIRAGLIO_GRACE: [
    "Julien Sullivan Grace, è nato nel 4075 della terza era a Umarsh, in una nobile e rispettata famiglia di Korianda. Il padre, un ammiraglio della flotta del paese nonché capo di stato maggiore della difesa, lo ha cresciuto con rigore e disciplina, insegnandogli fin da piccolo i valori dell'onore e del dovere.",
    "All'età di sedici anni lo inserisce nell'accademia militare e cinque anni dopo, nel 4096, lo fa arruolare nella marina militare con il grado di uffuciale guardiamarina. Julien dimostra subito un grande talento per la strategia e il comando, e in breve tempo scala le gerarchie fino a diventare capitano di una nave da guerra.",
    "Man mano che Julien aumentava di prestigio, il padre iniziò a includerlo sempre di più in affari di famiglia che da sempre gli erano stati preclusi.",
    "La famiglia dei Grace infatti aveva molti segreti, Julien lo sapeva bene. Lui comunque non aveva mai fatto domande, credendo di essere nel giusto in ogni cosa nel seguire il padre, fino a che la madre un giorno gli confessò che Korianda non era il paese che lui dovrebbe amare.",
    "Lui ne rimase confuso e perplesso, e quando provò a chiedere al padre, dopo molte insistenze, infine ottenne di sapere la vera origine della loro famiglia, ossia un antico popolo di Vaniaa da cui i loro antenati erano emigrati molti secoli prima.",
    "Il segreto che scoprì lo sconvolse e lo spinse a non fidarsi più delle parole del padre, tanto più quando il padre iniziò a parlare di una guerra di invasione contro Vaniaa, cosa che avrebbe creato solo distruzione gratuita.",
    "Nelle alte sfere e nei circoli militari, iniziò a girare la voce che padre e figlio non erano più uniti come un tempo, e questo fu considerato come un'occasione da alcuni loschi individui che tramavano nell'ombra, membri di una setta segreta, i Dol'Mon Doria.",
    "Essi avvicinarono Julien, con discrezione, fingendo amicizia e comprensione per le sue preoccupazioni. Quando però gli chiesero di collaborare con loro per destabilizzare il potere del padre, Grace, per lealtà alla marina e a Korianda, non accettò.",
    "Era sicuro di poter convincere il padre ad abbandonare l'idea di colonizzare Vaniaa. Quando però si accorse, durante una sua successiva promozione, che il padre era deciso sulla guerra al punto che iniziava a parlarne apertamente, Julien capì che le parole non sarebbero mai bastate e decise di agire.",
    "Riavvicinò i loschi individui che avevano provato a coinvolgerlo e collaborò con loro per l'ottenimento di un antico potere che avrebbe poto fermare i folli piani del padre.",
    "Per i loro piani era necessario spingersi a Vaniaa, così Julien accettò la promozione ad ammiraglio per ottenere il mandato del padre contro Vaniaa. Il padre penso che il figlio avesse finalmente capito e si sentì orgoglioso di lui.",
    "Ignorava che Julien aveva deciso di combatterlo con tutte le sue forze. L'ammiraglio Grace condusse una flotta con assoluta maestria fino alle coste di Vaniaa e in quel contesto collaborò con i Dol'Mon Doria per il recupero del reperto cercato.",
    "Una volta tornato in patria, l'ammiraglio Grace tradì i Dol'Mon Doria e usò il reperto per fortificare se stesso, invece di dividere con loro il potere.",
    "Distrusse i membri dela loro setta in un impeto di potere. Quello che Grace non si aspettava era di finire coinvolto in una millenaria guerra tra luce e ombra, e lui avrebbe dovuto scegliere una parte con cui stare.",
  ],
  YULIA_HELZEIDAMA: [
    "Yulia è nata a Trebitha nel 4091 della terza era. Il padre Rainer Helzeidama, era fuggito con il nonno Abbot Helzeidama nell'Olongea nel 3075, dopo gli avvenimenti catastrofici che distrussero l'ordine dei Collettori, di cui il nonno Abbot era membro come Scrutatore.",
    "Il nonno Abbot passò tutto il suo sapere al figlio Rainer, che prima del crollo dell'ordine era un suo apprendista. Rainer, a sua volta, si impegnlò ad educare Yulia sulla cultura dei Collettori per far si che quel sapere non andasse perduto.",
    "Yulia crebbe così in un ambiente di studio e di ricerca, e fin da piccola dimostrò una grande passione per la storia e la cultura dei Collettori. Il padre, era un uomo molto severo e autoritario, ma anche amorevole e paziente, e la incoraggiò a seguire le sue passioni che fossero in linea con l'educazione ricevuta.",
    "Così Yulia divenne una storica ed esperta del mondo antico di un certo rilievo. Lavorò per undici anni in uno studio di ricerca storica, dove si occupava di studiare i manufatti e i reperti archeologici dei Collettori.",
    "Dopo quello aprì un suo ufficio che gestiva in autonomia grazie alla rete di contatti e clienti che aveva costruito negli anni.",
    "Nel 3125 incontrò il suo futuro fidanzato, Nathan Ison, un veterinario del luogo che lei incontrò per caso quando portò a curare il suo gatto Faron per una ferita alla zampa.",
    "Loro si piacquero fin da subito e dopo un paio di anni in cui si frequentarono da amici, infine decisero di fidanzarsi. Iniziarono anche a parlare di matrimonio senza una vera fretta. Il vero problema in quel senso era ottenere la benedizione del padre di lei, a cui Nathan ambiva per una sua questione di integrità di coscenza.",
    "Perciò, Nathan si impegnava per piacere in tutto al padre di lei, ma il padre di Yulia non era convinto che Nathan fosse la scelta giusta per la figlia. Nathan era un uomo di umili origini, e non aveva la stessa cultura e la stessa educazione di Yulia.",
    "Questo però non scoraggia Nathan, che insiste per assecondare la mentalità del padre, fiducioso di fargli cambiare idea.",
  ],
  YOMU: [
    "Yomu Zaramatsu, detto il fantasma della lama di vento, è un guerriero del clan dei Lupi di Ghiaccio, un popolo nomade che vive nelle terre desolate del nord di Korianda.",
    "Cresciuto come un guerriero, Yomu ha sempre dimostrato una grande abilità nel combattimento e una forte determinazione nel raggiungere i suoi obiettivi.",
    "Fin da piccolo, ha imparato a cacciare e a combattere, e ha dimostrato una grande abilità nel maneggiare la spada e l'arco.",
    "Raggiunti i vent'anni, come per tutti i membri del clan più meritevoli, gli fu affidata una spada acherata con la quale avrebbe dovuto servire la causa del clan, ossia accumulare prestifio e ricchezze per ripristinarne il loro antico splendore.",
    "Il clan aveva una lunga storia di coinvolgimenti con il mondo dei mercenari o dei cercatori. Spesso i loro guerrieri venivano assoldati come truppe d'élite da vari signori della guerra o da mercanti in cerca di protezione.",
    "Yomu si fece sempre onore, e un giorno venne selezionato per lavorare come guardia del corpo di un mercante di reperti che avrebbe dovuto attraversare in incognito il paese.",
    "In due diverse occasioni, durante quel viaggio, Yomu salvò la vita del mercante, e in cambio il mercante gli offrì un posto fisso come guardia del corpo.",
    "Lui però non accettò, e preferì tornare al suo clan. Il mercante, colpito dalla sua lealtà e dalla sua abilità, gli offrì un'altra proposta: di lavorare come mercenario per lui di tanto in tanto.",
    "Yomu accettò, e da quel giorno iniziò a lavorare come mercenario per il mercante, viaggiando per tutto il paese e combattendo in numerose battaglie.",
    "Presto, il mercante sparse la voce del suo valore e Yomu venne incluso in spedizioni dei Cercatori sempre più prestigiose come truppa di supporto.",
    "I suoi successi cumulativi lo resero famoso nel giro dei mercenari, e presto divenne noto come il fantasma della lama di vento, per la sua abilità nel combattimento e per la sua capacità di scomparire nel nulla per poi ricomparire e colpire gli avversari.",
  ],
  GITWICK_VILLAR: [
    "Githwick ha vissuto tutta la vita a Korianda lavorando come mercenario. Non che avesse un particolare interesse per quel mondo, ma aveva sentito che era possibile arricchirsi relativamente con facilità, senza lavorare troppo.",
    "Fin da piccolo aveva sempre avuto un talento per evitare di faticare più del necessario, e questo lo aveva spinto ad unirsi a bande di ladruncoli dei bassifondi della città. Tra di essi, lui si distingueva per audacia e destrezza.",
    "Una volta cresciuto, si unì a una compagnia di mercenari, dove prestò servizio per diversi anni. Con il tempo riuscì anche a farsi una piccola rete di clienti fedeli. Principalmente questi clienti usavano il suo peschereccio per contrabbandare merci o far sparire ricercati spostandoli all'estero.",
    "Un giorno, però, uno dei suoi clienti lo tradì e lo consegnò alla guardia cittadina. Githwick fu arrestato e condannato a sei anni per i suoi crimini.",
    "Lui patteggiò e in cambio di una riduzione della pena a cinque mesi, egli avrebbe dovuto collaborare con la guardia per colpire il luogotenente di un boss locale del mercato di armi illegali.",
    "Dopo quel colpo, scontata la pena, Githwick fu rilasciato e si ritirò a vivere in una piccola casa in periferia, dove si manteneva facendo piccoli lavori di scorta e di trasporto.",
    "Assunse come socio un'uomo che aveva incontrato dentro. Villar, arrestato per ubriachezza molesta e schiamazzi, spese due settimane in cella con lui e si convinse che fosse un tipo affidabile.",
    "I due lavorarono assieme per molti anni facendo di tutto, compreso a volte tornare ad aiutare i contrabbandieri. Infine, dopo che un'altro cliente quasi li uccise, entrambi si arresero e si rifiutarono di continuare.",
    "Githwick e Villar si ritirarono dal giro dei mercenari e iniziarono a vivere come pescatori, lontano dalla città e dai suoi problemi, agendo occasionalmente anche come trasportatori per viaggiatori che non volevano essere visti.",
  ],
  LILITH: [
    "Lilith è nata nel 4096 della terza era a Korianda, in una famiglia di mercanti. Cresciuta con tre fratelli minori e due maggiori, Lilith ha da sempre imparato a battersi per ottenere ciò che le serviva.",
    "Nonostante fosse una ragazzina in gamba, era considerata la pecora nera della famiglia in un certo senso. A differenza dei fratelli non aveva alcun interesse per il commercio, e preferiva passare il tempo a leggere e a studiare.",
    "Il padre, un uomo d'affari di successo, non capiva la sua passione per la lettura e la cultura, e spesso la rimproverava per la sua pigrizia e la sua mancanza di ambizione.",
    "La madre, seppur volendole bene, non faceva che criticarla per i suoi gusti bizzarri e i suoi modi di fare.",
    "Lilith, però, non si sentiva a suo agio nel mondo del commercio, e non sapeva cosa fare della sua vita.",
    "Le cose per lei cambiarono durante il secondo anno della scuola superiore quando lei si spostò di istituto per seguire il padre dall'altra parte della città.",
    "In quel contesto, Lilith aveva incontrato una ragazzina chiamata Benna che la introdusse alla cultura spettrale e gotica di una setta che, a detta di Benna, era stata fondata per gioco da quelli dell'ultimo anno.",
    "Lei, sentendosi incuriosita da quel mondo, e un po' per avere finalmente un'amica, si lasciò coinvolgere e iniziò a frequentare il gruppo.",
    "La setta eseguiva incontri segreti e cerimonie in cui si parlava di spiriti e di demoni, e Lilith si appassionò subito a quelle storie.",
    "Iniziò a leggere libri e a studiare l'occulto, e presto divenne una delle più esperte del gruppo.",
    "In famiglia notarono il suo cambio di stile e di vestiario, e le liti con i genitori iniziarono a diventare sempre più frequenti.",
    "Un giorno, durante una cerimonia segreta, Lilith incontrò un ragazzo di nome Zane, che faceva parte di un'altra setta. Questa però, a differenza della sua, era molto più pericolosa e oscura.",
    "Essi volevano operare cose malvage e avevano come bersaglio alcuni dei membri della gilda dei purificatori, una gilda di cacciatori di demoni e altre creature oscure.",
    "Lei fu coinvoltà in un piano per attirare i purificatori in una trappola, e inizialmente accettò, ma poi si pentì e cercò di avvertire i purificatori.",
    "Grazie al suo aiuto, i purificatori riuscirono a sventare l'attacco e a catturare i membri della setta di Zane.",
    "Uno dei purificatori, grato alla giovane, decise di portarla nella sede locale della gilda per presentarla agli altri.",
    "In quel luogo lei fu folgorata dalla cultura e dall'ambiente. Tutte le persone presenti avevano l'aspetto di mercenari gotici e spettrali, e Lilith si sentì subito a suo agio.",
    "Il capo della sede locale, un uomo di nome Keen, la volle ringraziare di persona e quando notò che lei amava quell'ambiente, le propose di unirsi a loro e di diventare una purificatrice. Lei accettò con entusiasmo.",
    "Lilith fu addestrata da loro e divenne una purificatrice di grande talento, combattendo contro demoni e creature oscure in tutto il paese.",
    "Il padre, preoccupato per la salute della figlia, iniziò a chiedere nel suo ramo per qualche arma che potesse aiutarla e tramite un suo conoscente, un cercatore dell'Alveare, venne in contatto con un antico reperto con il potere di deprivare le streghe dei loro poteri, chiamato la catena di Relisse.",
    "Il padre glie la diede sperando che potesse proteggerla, e lei lo prese come un segno del destino, così decise di dedicarsi in particolare a quel ramo.",
    "In pochi anni riuscì ad eliminare molte streghe minori e quattro streghe maggiori, e si fece una fama in quel campo.",
    "La fama di Lilith iniziò ad attirare attenzioni che lei non gradiva, specialmente da parte di creature oscure in cerca di vendetta.",
    "Spinta da quelle dinamiche, Lilith doveva spostarsi di tanto in tanto e cambiare la sede della gilda per cui lavorava. L'ultimo gruppo di purificatori in cui fu accolta, gli Esperaz, sono oggi la sua casa e tra loro lei gode di grande stima.",
  ],
};
