import GalleryPage from "./Pages/GalleryPage/GalleryPage";
import HomePage from "./Pages/HomePage/HomePage";

import CharactersPage from "./Pages/CharactersPage/CharactersPage";
import LorePage from "./Pages/LorePage/LorePage";

export const AppRoutes = {
  HOME_PAGE: "/",
  GALLERY_PAGE: "/galleria",
  LORE_PAGE: "/lore",
  CHARACTERS_PAGE: "/personaggi",
};

export interface IAppRoute {
  path: string;
  displayName: string;
  component: JSX.Element;
  icon: string;
}

export const AppRoutesMap: IAppRoute[] = [
  {
    path: AppRoutes.HOME_PAGE,
    displayName: "Home",
    component: <HomePage />,
    icon: "/Assets/home.webp",
  },
  {
    path: AppRoutes.CHARACTERS_PAGE,
    displayName: "Personaggi",
    component: <CharactersPage />,
    icon: "/Assets/characters.webp",
  },
  {
    path: AppRoutes.LORE_PAGE + "*",
    displayName: "Lore",
    component: <LorePage />,
    icon: "/Assets/lore.webp",
  },
  {
    path: AppRoutes.GALLERY_PAGE,
    displayName: "Galleria",
    component: <GalleryPage />,
    icon: "/Assets/gallery.webp",
  },
];
