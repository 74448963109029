import { useNavigate } from "react-router-dom";
import { AppRoutesMap, IAppRoute } from "../../AppRoutes";
import "./Navigator.scss";
import { useEffect, useState } from "react";
import AudioUtil from "../../Utils/audioUtil";
export interface INavigatorProps {}

var menuIsOpen = false;
const Navigator = (props: INavigatorProps) => {
  const [menuOpen, setMenuOpen_] = useState<boolean>(false);
  const navigate = useNavigate();

  const setMenuOpen = (toggle: boolean) => {
    setMenuOpen_(toggle);
    menuIsOpen = toggle;
  };

  const handleRemoteToggle = () => {
    setMenuOpen(!menuIsOpen);
  };

  useEffect(() => {
    document.addEventListener("menu-toggle", handleRemoteToggle);
    return () => {
      document.removeEventListener("menu-toggle", handleRemoteToggle);
    };
  }, []);

  return (
    <div>
      <div
        className={
          "navigator-main-column-inkdrop " +
          (menuOpen ? "mobile-menu-is-open-inkdrop" : "")
        }
        onClick={() => {
          setMenuOpen(false);
        }}
      ></div>
      <div
        className={
          "navigator-main-column " + (menuOpen ? "mobile-menu-is-open" : "")
        }
      >
        <img src={"/Assets/menu_map_background_2.webp"} alt="menu" />
        {AppRoutesMap.map((x: IAppRoute, i: number) => {
          return (
            <div
              className="navigator-item"
              key={i}
              onClick={() => {
                AudioUtil.PlayAudio("mouseclick.wav");
                setMenuOpen(false);
                navigate(x.path.replaceAll("*", ""));
              }}
            >
              <div className="navigator-item-img">
                <img src={x.icon} />{" "}
              </div>
              <span>{x.displayName}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Navigator;
