import { useEffect, useState } from "react";
import { IImageDictionary } from "../../ImageDictionary";
import "./ImageDisplayer.scss";

export interface IImageDisplayerProps {
  image: IImageDictionary;
  isEvident: boolean;
  onSetEvident: (evident: boolean) => void;
}

const ImageDisplayer = (props: IImageDisplayerProps) => {
  const [evident, setEvident] = useState<boolean>(props.isEvident);

  useEffect(() => {
    setEvident(props.isEvident);
  }, [props.isEvident]);

  return (
    <>
      <div
        className="image-displayer-wrap"
        onClick={() => {
          props.onSetEvident(true);
        }}
      >
        <img
          className="display-pic"
          title={props.image.imageTitle}
          src={"./gallery/" + props.image.imageUrl + ".webp"}
        />
      </div>
      {evident && (
        <div
          className="evident-image-block"
          onClick={() => {
            props.onSetEvident(false);
          }}
        >
          <div>
            <img
              title={props.image.imageTitle}
              src={"./gallery/" + props.image.imageUrl + ".webp"}
            />
            <div className="evident-image-title">
              <small>Concetto {props.image.imageStyle}:</small>
              <div>{props.image.imageTitle}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageDisplayer;
