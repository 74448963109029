import { ILoreTextWall } from "./LoreTextWalls";

export const WALL: ILoreTextWall = {
  title: "Le fazioni",
  shortDescription: "Fazioni, gruppi e gilde",
  imagePath: "/assets/geomap-menu.webp",
  sections: [
    {
      title: "Collettori",
      shortDescription:
        "Il più antico ordine di collezionisti di tesori del passato",
      content: [
        {
          type: "title",
          value: "Collettori, l'ordine dei custodi del passato",
        },
        {
          type: "text",
          value:
            "L'ordine dei collettori è simile a un culto religioso con una missione, ossia recuperare e custodire i segreti delle ere passate. Questo per prevenire che i potenti " +
            "reperti perduti finissero in mano alle persone sbagliate, rischiando così di causare grande distruzione. Fondato nel 1931 dai monaci guerrieri Dimetreus e Fisir, " +
            "i quali riunirono sotto un'unica bandiera le molte organizzazioni sparse per Fedora che condividevano il principio della ricerca e custodia dei segreti passati. " +
            "Solo quelle con un certo livello di forza e etica furono coinvolte, e i capi riuniti impiegarono due anni per discutere i termini della dottrina dei collettori. " +
            "Da quel momento l'ordine divenne presto una potente macchina di ricerca, una vera e propria industria. In appena tre secoli l'ordine aveva raggiunto una fama " +
            "mondiale per i suoi risultati e per i suoi valori. Con il tempo, le tante diversità delle diverse organizzazioni riunite in esso andarono a perdersi e l'ordine " +
            "divenne coeso, cementato nel suo virtuoso credo quasi religioso. Entrare nell'ordine comportava un rituale sacramentale e il giuramento di onorare i dogmi dell'ordine. ",
        },
        {
          type: "text",
          value:
            "L'ordine ha una struttura gerarchica molto articolata, ma esistono alcuni gradi chiave che aiutano a capirne la forma. Questa struttura si fonda su tre principi: studio del mondo antico, missione sul campo e difesa interna. " +
            "I compiti delle tre branche dell'ordine sono ben distinti, erano anzi quasi indipendenti. In altre parole, anche un elemento di basso grado di una certa branca non subiva l'influenza di alti gradi di una branca diversa. " +
            "Ovviamente tra le diverse branche, sebbene la regola non lo imponesse esplicitamente, valevano aspetti come il rispetto degli anziani e la venerazione degli eroi, dinamiche che spingevano comunque i gradi inferiori ad onorare " +
            "l'autorità dei gradi superiori a prescindere dalla loro branca d'appartenenza. " +
            "All'interno di ogni branca, come ci si aspetterebbe, ogni grado comportava un insieme di doveri e onori, oltre che una serie di obiettivi sulla base dei quali i ranghi superiori valutavano se promuovere o retrocedere un certo membro. " +
            "Per ogni grado era previsto uno stipendio mensile e quasi sempre almeno un alloggio in una delle basi della regione dove si veniva stanziati. " +
            "Per quanto riguarda i gradi più alti, questi dovevano passare per vere e proprie cerimonie per essere dichiarati, e spesso in quei casi la notizia si propagava a tutte le basi dell'ordine sparse per il continente.",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src="/assets/collectors_ranks.webp" />
            </div>
          ),
        },
        {
          type: "title",
          value: "Il consiglio dell'arco",
        },
        {
          type: "text",
          value:
            "Il ruolo di consigliere dell'arco era l'unico grado nell'ordine che non si poteva guadagnare per merito o tramite raccomandazione. " +
            "Era più che altro un grado onorifico, e rappresentava il traguardo massimo nell'ordine. Un collettore diventava automaticamente consigliere dell'arco solo " +
            "se aveva raggiunto il grado di Gran lettore, Grand'araldo o Grand'architetto quando esso si trovava a dover cedere il suo posto ad un altro membro più giovane che lo stesse rimpiazzando. " +
            "Il consiglio dell'arco aveva lo scopo di suggerire ai collettori di grado subito inferiore come agire. Non aveva potere decisionale. " +
            "Era la saggezza dei membri del consiglio ad avvalorare le sue deliberazioni. Sorprendentemente, quello era l'unico grado che un collettore potesse decidere di rifiutare, " +
            "nel caso uno avesse voluto ritirarsi a vita privata. " +
            "In generale se un ruolo di alto ufficio si trova vacante per la morte prematura di colui che lo ricopriva, il consiglio dell'arco aveva il potere di selezionare tra i collettori " +
            "di grado subito inferiore colui che sarebbe andato a ricoprire tale grado in via temporanea, fino a che non si potessero svolgere le dovute procedure per la selezione di un membro " +
            "che coprisse la carica in modo permanente. " +
            "Lo stesso procedimento si applicava se un membro che ricopriva un ruolo di alto ufficio veniva meno a uno dei dogmi sacri dell'ordine, con conseguente esilio da parte " +
            "dell'ordine e pena di morte. ",
        },
        {
          type: "text",
          value:
            "Il nome del consiglio è stato dato in onore degli antichi sacerdoti dell'arco, riconosciuti da tutti gli studiosi come i più antichi praticanti di quello che sarebbe divenuto poi il culto dei sette.",
        },
        {
          type: "title",
          value: "Branca della ricerca",
        },
        {
          type: "text",
          value:
            "La branca della ricerca si occupa di studiare i reperti antichi e di scoprire i segreti del passato. " +
            "Ha il compito di catalogare i reperti e di conservarli in modo sicuro, ma anche di approfondire minuziosamente le informazioni ottenute da ogni ritrovamento. " +
            "Sono considerati i secchioni dell'ordine, e sono spesso visti come i più noiosi dai membri delle altre branche. ",
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Gran lettore: Questo grado è concesso dal precedente gran
                  lettore al lettore o scrutatore che si sia distinto rispetto i
                  suoi parigrado nell’arte della decodifica o della ricerca. Può
                  esistere un solo Gran lettore alla volta. Il gran lettore ha
                  accesso a tutti gli archivi dell’ordine, decide quale ricerca
                  deve essere portata a vanti e quali distaccamenti devono
                  farlo. Presiede in oltre la corte marziale che giudica i
                  collettori d’alto rango incriminati di aver trasgredito i
                  dogmi.
                </li>
                <li>
                  Lettore: Il grado di lettore è conferito a coloro che
                  dimostrano profonda conoscenza della cultura del mondo antico
                  e particolare destrezza nel muoversi tra i concetti pregni
                  della mentalità del tempo. Ricopre il ruolo di mediatore con
                  l’antica favella, perciò è tenuto a studiare i vecchi reperti
                  che non hanno ancora trovato una traduzione chiara. Richiede
                  una certa anzianità.
                </li>
                <li>
                  Scrutatore: Tale grado è assegnato agli scribi che si sono
                  spinti ben oltre il semplice riprodurre opere antiche. Se
                  durante la loro opera di ristrutturazione e chiarificazione
                  dei testi ritrovati intravedono verità perdute e le segnalano,
                  essi guadagnano immediatamente il grado di scrutatore.
                </li>
                <li>
                  Scriba: Come lascia intendere il nome, lo scriba è colui che
                  copia, rinnova e moltiplica le fonti d’apprendimento del mondo
                  antico. È un ruolo che viene dato ai gradi inferiori che
                  abbiano accumulato anzianità nei rispettivi ruoli.
                </li>
                <li>
                  Pensatore: Il pensatore ha il ruolo di rivedere le antiche
                  traduzioni e studiarne il significato da prospettive diverse.
                  Il grado di pensatore è dato agli archivisti che abbiano
                  dimostrato di saper discernere l’importanza dei vari reperti
                  basandosi sulla propria cultura del mondo antico, piuttosto
                  che sui consigli dei loro maestri.
                </li>
                <li>
                  Librariano: Oltre che preparare gli studenti dell’ordine, il
                  librariano è un ricercatore attivo. Comparando, schematizzando
                  e memorizzando centinaia di libri, ha il dovere di riportare
                  qualunque discrepanza nelle attive concezioni della cultura
                  antica. Gli specifici argomenti che un librariano decide di
                  approfondire sono di sua discrezione soltanto.
                </li>
                <li>
                  Archivista: L’archivista ricolloca, ordina ed elenca i reperti
                  custoditi nella propria sede. Gli archivisti hanno il ruolo di
                  memoria a lungo termine dell’ordine, ed a volte accedono alle
                  immense banche sotterranee per riesumare i reperti su
                  richiesta dei loro superiori.
                </li>
                <li>
                  Gestatore: I collettori che abbiano completato il corso
                  studentesco di preparazione allo studio del mondo antico
                  devono seguire un periodo di disponibilità al servizio dei
                  librariani. Possono avviare progetti di ricerca personali, ma
                  questi non devono interferire con le richieste imposte dai
                  loro superiori.
                </li>
                <li>
                  Portatore: È colui che approccia la carriera della ricerca dal
                  punto di vista del controllo e della gestione. Ha il ruolo di
                  trasportare le richieste formali di prelievo di un artefatto
                  agli archivisti, oltre che dover poi trasportare al mittente
                  l’artefatto. Tale ruolo ha adempimento solo in sede. Per
                  trasportare artefatti da una sede all’altra si usano
                  battaglioni di paladini accompagnati da due archivisti.
                </li>
                <li>
                  Studente: Colui che sceglie la carriera della ricerca dal
                  punto di vista dello studio e della decifrazione. Ha il ruolo
                  di seguire le lezioni di lingua e cultura antica di livello
                  avanzato che vengono impartite dai librariani. Non ha alcun
                  compito a parte quello di studiare, ma talvolta possono
                  ricevere piccoli incarichi da svolgere in sede.
                </li>
                <li>
                  Accademico: Colui che sceglie di far parte della sezione
                  culturale dell’ordine. È tenuto a seguire cinque anni di
                  lezioni sulla cultura antica e a superare un esame finale.
                  Completato lo studio, deve scegliere che branca seguire tra
                  gestione e ricerca
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "Branca dell'espansione",
        },
        {
          type: "text",
          value:
            "La branca dell'espansione si occupa di recuperare i reperti antichi e di proteggere i territori dell'ordine. " +
            "I guerrieri dell'ordine sono tutti sotto questa branca, che volendo si può considerare come la sezione militare dell'ordine. ",
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Grand’araldo: È Il rappresentante dell’ordine agli occhi del
                  mondo, internamente è il comandante di tutte le unità sul
                  campo. Gestisce la ricerca esplorativa, la disposizione degli
                  uomini e i tempi d’azione. Tale grado è conferito all’araldo o
                  al gran maestro che si è distinto o nella scoperta di nuovi
                  reperti o nella difesa delle sedi. Richiede una certa
                  anzianità. Può esserci un solo Grand’araldo alla volta.
                  Presiede in oltre la corte marziale che giudica i collettori
                  d’alto rango incriminati di aver trasgredito i dogmi.
                </li>
                <li>
                  Araldo: Emissario dell’ordine sul campo. Ha la funzione di
                  esploratore d’alto livello, rappresenta la truppa speciale da
                  inviare nei luoghi più pericolosi. Essi agiscono anche come
                  ambasciatori dell’ordine, ed hanno la responsabilità di
                  trasportare messaggi di alta priorità tra le varie sedi o
                  verso enti esterni.
                </li>
                <li>
                  Gran maestro: Il gran maestro è il grado assegnato ai
                  guerrieri che abbiano raggiunto una certa anzianità come
                  maestri e che si siano distinti nelle situazioni di pericolo.
                  Il gran maestro ha il ruolo di gestire i maestri della propria
                  sede. Rispondono solo al grand’araldo. Anche se il loro grado
                  non è massimo, sono profondamente rispettati da tutti i membri
                  dell’ordine.
                </li>
                <li>
                  Maestro: Un collettore che abbia raggiunto padronanza in
                  un’arte del combattimento a livello tale da poter illuminare i
                  propri compagni viene dichiarato maestro. Il grado di maestro
                  è relativo solo alla preparazione del collettore, non ci sono
                  limiti d’età. Tra i vari compiti, un maestro è anche tenuto a
                  preparare nei rispettivi campi i portavoce, i vaganti e i
                  custodi della sua sede.
                </li>
                <li>
                  Missionario: Il missionario ha ruolo di inviato dell’ordine
                  come portavoce o esploratore delle zone oltre l’egemonia
                  dell’ordine. Tali zone variano da città a deserti e terre
                  selvagge. È il grado assegnato ai portavoce che hanno
                  dimostrato di sapersela cavare in situazione di discreta
                  difficoltà. Trasporta anche i messaggi di media priorità tra
                  le varie sedi o verso enti esterni.
                </li>
                <li>
                  Paladino: Il paladino è il guerriero di prima linea
                  dell’ordine. Sebbene insegni la cultura ed il rispetto della
                  vita, l’ordine non tollera attacchi diretti alle proprie sedi
                  o alle popolazioni sotto la sua protezione. Coloro che
                  minacciano la vita dei collettori o dei loro alleati vengono a
                  loro volta minacciati. Coloro che attaccano sono a loro volta
                  attaccati. Qualunque mossa bellica dell’ordine non va oltre la
                  difesa delle terre conquistate tramite il dialogo o l’egemonia
                  religiosa. Il grado di paladino si conferisce al vagante o al
                  custode che abbia riportato una numerosa quantità di vittorie
                  sul campo o una singola eclatante vittoria.
                </li>
                <li>
                  Vagante: Il vagante è la truppa da combattimento dell’ordine
                  che viene inviata a patrocinare i luoghi sotto il rischio di
                  attacco. Il grado di vagante è conferito ai fidelis che
                  sentono di voler servire l’ordine proteggendo gli alleati
                  lontani.
                </li>
                <li>
                  Custode: Il custode è la truppa da combattimento dell’ordine
                  che risiede perennemente nelle sedi o negli edifici di
                  esclusiva giurisdizione dell’ordine. Il grado di custode è
                  conferito ai fidelis che sentono di voler servire l’ordine
                  proteggendone la casa e i tesori.
                </li>
                <li>
                  Portavoce: Come suggerisce il nome, il portavoce è il
                  messaggero dell’ordine. È tenuto a trasportare i messaggi di
                  bassa priorità tra le varie sedi o verso enti esterni
                  all’ordine. Senza di essi sarebbe impossibile gestire i vasti
                  domini d’interesse.
                </li>
                <li>
                  Fidelis: Il fidelis è colui che sente di dover servire
                  l’ordine aiutandolo a combattere quelle battaglie che le
                  parole non possono affrontare. Deve addestrarsi in un’arte del
                  combattimento al fine di poter essere schierato come truppa di
                  difesa dei domini dei collettori.
                </li>
                <li>
                  Uditore: L’uditore è colui che vuole mediare tra coloro che
                  sono esterni all’ordine e le cerchie interne. Ha il compito di
                  viaggiare, raccogliendo richieste e preghiere in giro per il
                  mondo. Quando lo ritiene opportuno, l’uditore consegna le
                  richieste che ritiene importanti segnalare alla sede più
                  vicina. Rappresenta il contatto con le classi povere o
                  dimenticate di tutte le società. L’uditore che segnala
                  problemi rilevanti può diventare portavoce dell’ordine.
                </li>
                <li>
                  Attivista: Colui che sceglie di far parte della sezione
                  militare o sociale dell’ordine. Dopo aver frequentato un
                  addestramento pseudo bellico di cinque anni può scegliere se
                  dedicarsi alla branca sociale ed espansiva dell’ordine o a
                  quella bellica e difensiva.
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "Branca della gestione interna",
        },
        {
          type: "text",
          value:
            "La branca della gestione interna si occupa di tutti gli aspetti ministeriali, ufficiali, burocratici e logistici. " +
            "Si occupa di gestire le sedi dell'ordine, di organizzare le spedizioni e di mantenere i contatti con le altre organizzazioni. ",
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Grand’architetto: È colui che pianifica e ordina la
                  costruzione di nuove sedi, templi o banche degli artefatti.
                  Orienta le attività edilizie e progetta i piani di
                  ristrutturazione delle costruzioni dell’ordine. Ha anche ruolo
                  di gestore burocratico di massimo livello. Determina nascita,
                  morte e alterazioni di funzioni e procedure da applicare
                  nell’ordine. Può anche suggerire, se i tempi lo richiedono,
                  alterazioni al libro dei dogmi. Tali suggerimenti nascono da
                  riunioni con le alte cariche dell’ordine e successivamente
                  vengono passate al consiglio dell’arco che deve valutare se
                  approvare la modifica. Può esserci un solo grand’architetto
                  alla volta. Presiede in oltre la corte marziale che giudica i
                  collettori d’alto rango incriminati di aver trasgredito i
                  dogmi.
                </li>
                <li>
                  Cerimoniere: I cerimonieri si occupano di tutte le funzioni
                  burocratiche dell’ordine, sia in sede che fuori sede. Solo
                  documenti controfirmati da un cerimoniere hanno natura
                  ufficiale all’interno dell’ordine. Essi decidono anche la
                  priorità che un messaggio o un progetto deve avere, stilando
                  così una lista degli obbiettivi generali. Investono, inoltre,
                  il ruolo di magistratura d’alto rango. Sentenziano le dispute
                  tra i collettori di grado pari o inferiore al loro. Nel caso
                  che la disputa coinvolga un collettore di grado superiore, il
                  cerimoniere passa i documenti relativi a tale disputa
                  direttamente al consiglio dell’arco. Solamente chi ha maturato
                  una certa anzianità come artigiano può essere eletto
                  cerimoniere.
                </li>
                <li>
                  Architetto: Ha il compito di gestire le imprese edilizie e di
                  ristrutturazione dell’ordine. Seguendo le direttive del
                  grand’architetto, ha il ruolo di progettare nel dettaglio un
                  piano di lavoro che dovrà essere portato avanti dalle cariche
                  inferiori. Solo chi si dimostra grande conoscitore della terra
                  e dei suoi materiali può accedere a tale rango.
                </li>
                <li>
                  Artigiano: Il grado di artigiano è dato a coloro che hanno
                  dimostrato di saper strutturare, ordinare e dirigere opere di
                  impalcatura burocratica o edilizia. L’artigiano riceve i
                  progetti degli architetti o i documenti dei cerimonieri e si
                  applica perché vengano messi in pratica. Solo chi abbia
                  maturato esperienza gestionale o scientifica può accedere a
                  tale rango.
                </li>
                <li>
                  Supervisore: Ha lo scopo di tutelare i diritti e doveri dei
                  collettori. In ogni campo, i supervisori si assicurano che il
                  rispetto tra collettori sia bilaterale, a prescindere dal
                  rango. Presenziano nelle situazioni che necessitano di
                  testimoni ufficiali e rappresentano la magistratura tra i
                  membri di medio e basso livello. Un supervisore non può
                  condannare un collettore di grado superiore. Le denunce che
                  giungono ad un supervisore che coinvolgono membri di grado a
                  lui superiore non possono essere esaminate dallo stesso.
                  Tuttavia, può scavalcare i gradi sino a coinvolgere un
                  cerimoniere, nel caso sia necessario che l’ingiustizia giunga
                  ad un orecchio così autorevole.
                </li>
                <li>
                  Alchimista: Profondo conoscitore delle proprietà dei
                  materiali, ha lo scopo di impartire gli ordini riguardo le
                  opere edilizie direttamente sul campo. Oltre ciò, è attivo
                  ricercatore e studioso delle scienze della chimica ed
                  alchimia, usate per plasmare anche alcune leghe segrete del
                  vecchio mondo.
                </li>
                <li>
                  Saggiatore: Il saggiatore è il tecnico sul campo. Sotto la
                  guida di un alchimista, si occupa di edificare le strutture
                  dei collettori. Il saggiatore che sperimenta nuove tecniche di
                  edificazione o che dimostra capacità di prevenzione dei danni
                  viene promosso alchimista. Perché avvenga la promozione, è
                  necessario che il saggiatore dimostri anche una valida
                  preparazione tecnica.
                </li>
                <li>
                  Credentore: I collettori con questa carica si occupano delle
                  questioni legali e delle procedure mercantili tra l’ordine ed
                  esterni. I credentori si occupano anche di burocrazia interna
                  di basso livello. Tramite un’opera di sapiente filtraggio
                  delle informazioni, permettono alle cariche superiori di
                  svolgere in modo più efficiente il loro ruolo. La gestione del
                  personale esterno all’ordine che lavora al fianco dei
                  collettori è affidata a loro.
                </li>
                <li>
                  Manovale: Occupano il ruolo di mano d’opera, molto numerosi. I
                  loro compiti vanno dal procurare parte delle materie prime, a
                  forgiare strumenti e lavorare i materiali. La serietà e le
                  abilità dei manovali sono continuamente controllate dagli
                  alchimisti, le cui opinioni ne determinano il procedimento
                  nella carriera. È un ruolo molto ambito da chi si unisce
                  all’ordine solo per sfruttarlo come fonte di lavoro, e questo
                  causa spesso una gran differenza di dedizione e fedeltà tra
                  questo grado e quelli direttamente superiori.
                </li>
                <li>
                  Passacarte: Con lo scopo di ausiliare l’attività dei
                  saggiatori, i passacarte si occupano dei lavori burocratici
                  più meccanici. Dai permessi, alle iscrizioni ai settori dei
                  novizi. Tutta la burocrazia di basso livello passa per loro.
                  In particolare, essi hanno anche il compito di fornire
                  informazioni di base e supporto agli esterni che volessero
                  unirsi all’ordine.
                </li>
                <li>
                  Apprendista: Colui che sceglie di far parte della sezione
                  gestionale dell’ordine. Dopo aver frequentato cinque anni di
                  preparazione alle discipline ingegneristiche o giuridiche di
                  base, deve scegliere se dedicarsi all’impalcatura burocratica
                  o fisica dell’ordine.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      title: "Cercatori",
      shortDescription: "Lega di esploratori e cacciatori di tesori",
      content: [
        {
          type: "title",
          value: "La nascita della lega dei cercatori",
        },
        {
          type: "text",
          value:
            "Durante il terzo millennio della terza era, a seguito del successo che avevano avuto i Collettori, sempre più persone decisero di imitarne il comportamento. " +
            "Prima come singoli individui, poi come piccoli gruppi, infine come vere e proprie organizzazioni. " +
            "Presto nacquero le prime gilde dei cercatori e in breve tempo si diffusero in tutto il continente di Fedora. " +
            "A differenza dei Collettori, i cercatori non avevano un gredo ne una gerarchia militare interna. Si trattava di gruppi di esploratori e cacciatori di tesori, " +
            "che lavoravano insieme per trovare e recuperare reperti antichi che poi vendevano a collezionisti e magnati. " +
            "I cercatori con il tempo acquisirono una certa fama e rispetto, e divennero una delle gilde più potenti e influenti di Fedora. " +
            "Sebbene siano mossi dal guadagno più che da ideali sul bene comune e la conoscenza, i cercatori sono comunque considerati un gruppo di intellettuali e studiosi. ",
        },
        {
          type: "text",
          value:
            "Visto l'impatto che stavano riscontrando, i governi di tutti i paesi sentirono il bisogno di regolamentare quel flusso di mercato sotto una struttura istituzionalizzata. " +
            "La corte dei sette, organo che era assieme al consiglio dell'arco dei Collettori la più alta autorità in materia di cultura e storia, decise di fondare la lega dei cercatori. " +
            "Seppur mantenendo la predilizione per i Collettori, più vicini per costituzione alla fede nei sette, la corte decise di dare ai cercatori un'organizzazione con molti vantaggi ." +
            "La lega dei cercatori fu fondata nel 2811 in Korianda, e si estese nel 2843 nell'Olongea, e due anni dopo nel 2845 in Migulma e Dulenvir. Giunse più tardi anche in Phivalla nel 3101. " +
            "In ognuno dei cinque stati di Fedora, la lega dei cercatori ha una sede centrale, che coordina le attività dei cercatori in quel paese e funge da rifugio per i cercatori. " +
            "Ogni sede è sempre costruita come una cittadella indipendente e fortificata, pensata come un'ambasciata, e gode di immunità diplomatica. La cittadella dei cercatori è autonoma dal punto di vista dei servizi principali, dispone di terreni abitati e coltivati, e " +
            "spesso offre varie aree di allenamento per i cercatori. Prevede anche un'accademia per la formazione dei nuovi cercatori, e un'area di stoccaggio per i reperti recuperati che solitamente coincide con i quartieri degli uffici. ",
        },
        {
          type: "text",
          value:
            "Al fine di poter gestire meglio la burocrazia e la contabilità delle azioni di ogni singolo cercatore, ogni cercatore abilitato deve essere in possesso di una licenza acquisita secondo gli " +
            "opportuni canali istituzionali, ed è richiesto per legge anche che sia iscritto almeno ad un ufficio. Gli uffici dei cercatori sono strutture che si occupano di gestire le richieste dei clienti e di archiviare le informazioni " +
            "sullo storico dei lavori completati. Ogni ufficio è gestito da un capo ufficio, che è responsabile della gestione delle risorse e del personale. " +
            "Ogni ufficio è collegato alla sede centrale del paese in cui si trova, e invia regolarmente rapporti sulle attività svolte. ",
        },
        {
          type: "title",
          value: "Le classi dei cercatori",
        },
        {
          type: "text",
          value:
            "La lega dei cercatori nasce con un obiettivo molto chiaro: recuperare i reperti antichi e custodirli. Le alte sfere dei Collettori e della corte dei sette sperano di usare " +
            "la forza lavoro di tanti esploratori per accelerare le ricerche dei reperti più importanti ancora dispersi, considerandoli una minaccia per la stabilità del mondo. " +
            "Così, volendo forzare la trasparenza dei risultati, i cercatori sono obbligati a consegnare alla sede centrale i documenti che descrivono tutti i reperti trovati e il luogo in cui sono stati rinvenuti, " +
            "così da aituare a creare un'inventario di tutte le zone esplorate e stendere una mappa delle antiche culture rinvenute. ",
        },
        {
          type: "text",
          value:
            "Per scremare ulteriormente i lavori e garantire che i reperti più importanti non vadano persi, la lega dei cercatori ha suddiviso i reperti antichi in classi e i cercatori a loro volta sono suddivisi in categorie che li rispecchiano. " +
            "A seconda della classe vengono offerti benefici diversi, e le classi più alte godono di un prestigio eccezionale. Queste sono le classi dalla D alla A. " +
            "I cercatori di classe D sono dei novizi e perchè siano riconosciuti è sufficiente il ritrovo di un qualunque reperto e l'iscrizione ad un ufficio valido. " +
            "I cercatori di classe C sono coloro che hanno maturato tutti i requisiti della classe D, e in più trovato almeno un reperto di valore congruo, ossia un reperto di classe C. " +
            "I cercatori di classe B sono coloro che hanno maturato tutti i requisiti della classe C, devono aver anche maturato almeno cinque anni di esperienza e trovato almeno un reperto di valore congruo, ossia un reperto di classe B. " +
            "I cercatori di classe A sono coloro che hanno maturato tutti i requisiti della classe B, devono aver anche maturato almeno otto anni di esperienza e trovato almeno un reperto di valore congruo, ossia un reperto di classe A. " +
            "I cercatori di classe A sono chiamati anche cercatori della prima lega, e sono spesso persone con doti eccezionali. Rappresentano l'eccellenza della tecnica e della ricerca, e gli è garantita una pensione e una terra in uno stato a loro scelta. ",
        },
        {
          type: "text",
          value:
            "I cercatori, come anticipato, godono di vari privilegi. Uno dei più ambiti privilegi è che ai cercatori di una certa classe è consentito, a pagamento, l'accesso agli archivi centrali sui dati dei lavori completati da qualunque altro cercatore che appartenga a una classe pari o inferiore. " +
            "Questo per consentire la costruzione di una rete di informazioni che aiuti ad accelerare il ritrovo dei reperti. Oltre a questo, i benefit si estendono a tutte le sfere della vita che possono interferire con " +
            "la crescita dei cercatori di talento. Ad esempio, un'altro benefit molto apprezzato viene sbloccato a partire dalla classe C e consente a un cercatore di svincolarsi dall'obbligo di leva militare, anche in caso di legge marziale o guerra. " +
            "Ai cercatori di classe B viene concesso un bonus di stipendio e la possibilità di avere un alloggio gratuito in ognuna delle basi della lega. " +
            "Ai cercatori di classe A è persino concesso un canale preferenziale di ascesa nelle istituzioni che governano la lega dei cercatori. ",
        },
      ],
    },
    {
      title: "Mercenari",
      shortDescription: "Gilda di guerrieri e assassini a pagamento",
      content: [
        {
          type: "title",
          value: "I mercenari, i guerrieri senza padrone",
        },
        {
          type: "text",
          value:
            "I mercenari sono guerrieri senza padrone, che vendono le loro abilità al miglior offerente. " +
            "Sono spesso ex soldati o avventurieri che hanno scelto di guadagnarsi da vivere combattendo. " +
            "I mercenari sono divisi in varie gilde, ognuna con i propri codici e regole. " +
            "Le gilde dei mercenari sono spesso coinvolte in guerre e conflitti, e sono temute e rispettate in tutto il mondo. " +
            "I mercenari sono spesso al servizio di re e signori, ma possono anche essere assoldati da privati o da organizzazioni criminali.",
        },
        {
          type: "text",
          value:
            "Nati spontaneamente in modi indipendenti in giro per tutto il continente di Fedora, i governi dei vari stati hanno sentito l'esigenza di " +
            "regolamentarli e inserirli all'interno di gilde strutturate al fine di poterne tracciare le attività. " +
            "La lega delle gilde dei mercenari fu fondata nel 2657 in Korianda e venne riformata nel 2921 la prima volta, nel 3431 la seconda volta infine nel 4010 la terza e ultima volta. " +
            "Le riforme hanno permesso l'evoluzione dell'azione di questi gruppi da bande di briganti a veri e propri eserciti privati. " +
            "Oggi le gilde dei mercenari sono un'organizzazione molto potente e influente, e sono coinvolte in molte operazioni sia legali ed etiche, sia illegali. " +
            "Gran parte del traffico di armi e di droga in tutto il mondo spesso li vede infatti coinvolti. ",
        },
        {
          type: "title",
          value: "I purificatori, i cacciatori di mostri",
        },
        {
          type: "text",
          value:
            "I purificatori, i cacciatori di mostri, sono un ramo della gilda dei mercenari della gilda di Weyland, che " +
            "nel 2830 si separarono dalla gilda e fondò il suo gruppo indipendente. I purificatori nascono come soldati " +
            "specializzati nel combattere le creature oscure e con il tempo assunsero il ruolo di cacciatori di demoni e altri esseri maligni. " +
            "La loro fama gli permise di prendere il controllo di quel settore molto specifico del mercato delle operazioni da mercenari, e " +
            "visto che non era un campo molto ambito, non ebbero problemi a mantenere il monopolio. ",
        },
        {
          type: "text",
          value:
            "I purificatori crebbero in forze e fama nei secoli successivi. Affrontarono una distruzione quasi totale nel 2049 " +
            "quando attirarono su di se le ire di una srtega suprema, che li attaccò nella loro base principale. " +
            "La loro esperienza nel campo gli permise alla fine di spuntarla e da quella vittoria la loro fama raggiunse nuovi picchi. ",
        },
        {
          type: "text",
          value:
            "Oggi i purificatori si occupano principalmente di eliminare le creature oscure che minacciano i piccoli villaggi, che sono di esse i bersagli preferiti. " +
            "Occasionalmente sono coinvolti in operazioni di mercenari o cercatori che prevedono un alto rischio di confronto con uno di questi " +
            "esseri oscuri. La loro fortuna e la loro economia si basa su un sistema di ricompense per le teste dei mostri che uccidono. " +
            "A queste si aggiunge una sorta di tassa di protezione che chiedono ai villaggi sotto la loro custodia. ",
        },
        {
          type: "title",
          value: "I cacciatori di taglie",
        },
        {
          type: "text",
          value:
            "I cacciatori di taglie sono sempre esistiti in Fedora a partire dal terzo millennio della terza era. A differenza dei mercenari, che lavorano per un datore di lavoro, i cacciatori di taglie lavorano per conto proprio. " +
            "Inizlamente persone indipendenti che lavoravano facendosi pagare dai piccoli governi locali, a partire dal 2542 essi furono riuniti in una gilda. " +
            "Oggi rispondono al Craviro, l'ente internazionale di giustizia nei confronti dei criminali più pericolosi. Questa istituzione si fa anche carico di regolamentare la pubblicazione delle taglie. " +
            "I cacciatori di taglie sono spesso visti come eroi, ma in realtà sono solo persone che cercano di guadagnarsi da vivere. " +
            "La loro vita è molto pericolosa e spesso non durano a lungo. " +
            "I cacciatori di taglie sono divisi in varie categorie, a seconda del tipo di taglie che cacciano. " +
            "I cacciatori semplici danno la caccia a criminali di basso livello, mentre i cacciatori di taglie speciali cacciano criminali più pericolosi. " +
            "Infine, esistono i cacciatori di taglie élite, che cacciano criminali con la fama di essere imprendibili. ",
        },
      ],
    },
    {
      title: "Fazioni del Gunebar",
      shortDescription: "Le fazioni tipiche delle isole dell'est",
      content: [
        {
          type: "title",
          value: "Gli Starjammer, gilida di navigatori del Gunebar",
        },
        {
          type: "text",
          value:
            "Gli Starjammer sono nati come un gruppo di famigerati pirati che infestavano le acque del Gunebar, in particolare nel sud di Libaldat. " +
            "La loro forza, abilità e coraggio li resero famosi in tutto il continente, e presto divennero una vera e propria gilda di navigatori. " +
            "Un principe locale, riconoscendone il valore, decise di regolarizzarli nel 2039 della terza era. Perdonò i loro crimini e li assunse come suoi navigatori personali. " +
            "Da quel momento, gli Starjammer divennero una delle gilde più potenti e rispettate del Gunebar. ",
        },
        {
          type: "text",
          value:
            "Quando il principe morì, gli Starjammer si divisero in due fazioni, una che voleva continuare a servire il principe e l'altra che voleva tornare alla pirateria. " +
            "La guerra civile che ne seguì durò per molti anni, e alla fine la fazione che voleva continuare a servire il principe vinse. " +
            "Gli Starjammer divennero così i navigatori ufficiali del regno, e la loro fama crebbe ancora di più. " +
            "A loro fu assegnato il compito di creare una rotta sicura fino al continente di Fedora, e in poco tempo riuscirono a farlo. " +
            "Oggi gli Starjammer sono conosciuti in tutto il mondo come i migliori navigatori del Gunebar. ",
        },
        {
          type: "title",
          value: "Gli Ulrak, guerrieri discendenti degli Irlaki",
        },
        {
          type: "text",
          value:
            "Gli Ulrak sono un popolo di guerrieri che discendono dagli Irlaki, un antico popolo di guerrieri che abitava le isole del Gunebar. " +
            "Gli Irlaki erano conosciuti per la loro abilità nel combattimento e per il loro coraggio, nonché per la loro destrezza nell'uso del Dharma Talgalla, " +
            "arte che avevano appreso dal profeta Opsei nei secoli precedenti. " +
            "Nel 1957 poi si sciolsero per ordine dei reggenti a cui erano fedeli, e si dispersero in tutto il Gunebar. " +
            "L'ultima frazione che rimane fedele al vecchio ordinamento è quella degli Ulrak, che si sono stabiliti a Libalp. " +
            "Gli Urlak sono fedeli al loro codice d'onore e alla loro tradizione, e sono rispettati e temuti in tutto il Gunebar. " +
            "Attualmente servono un solo signore, il loro principe, che in cambio dei loro servizi garantisce loro la continuità della loro fazione. ",
        },
        {
          type: "title",
          value: "I Shikan, gli artigiani acheratori del Gunebar",
        },
        {
          type: "text",
          value:
            "Gli artigiani del Gunebar sono i migliori del mondo, e in particolare la fazione degli Shikan. " +
            "Gli Shikan sono conosciuti per la loro abilità nel lavorare il metallo e la pietra, e per la loro capacità di creare oggetti acherati. " +
            "I loro manufatti sono molto ricercati in tutto il mondo, e sono considerati tra i migliori sul mercato. ",
        },
        {
          type: "text",
          value:
            "La gilda degli artigiani ha aperto alcune piccole filiali anche in Fedora, ma sono più che altro centri di distrubuzione dei prodotti che vengono creati nel Gunebar. " +
            "Gli Shikan sono molto orgogliosi della loro arte e della loro tradizione, e sono disposti a tutto pur di preservarla. " +
            "La loro ricchezza è nota a tutti, e i sei capi della gilda sono tra le persone più ricche del Gunebar. " +
            "Nel tempo moderno sono rimasti gli unici a saper ancora realizzare oggetti acherati, arte che nel resto del mondo è andata perduta. ",
        },
      ],
    },
  ],
};
