import "./HomeBigBlock.scss";

export interface IHomeBigBlockProps {
  img: string;
  title: string;
  content: string;
  side: "left" | "right";
}

const HomeBigBlock = (props: IHomeBigBlockProps) => {
  return (
    <div
      className="home-big-block-wrap"
      style={props.side === "left" ? { flexDirection: "row-reverse" } : {}}
    >
      <div className="home-big-block-img-wrap">
        <img src={props.img} className="home-big-block-img" />
      </div>
      <div className="home-big-block-content-wrap">
        <div className="home-big-block-content-title">{props.title}</div>
        <div className="home-big-block-content-body">{props.content}</div>
      </div>
    </div>
  );
};

export default HomeBigBlock;
